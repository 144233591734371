import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';

import classes from './Wrapper.scss';

type WrapperProps = {
    className?: string;
    bg?: 'white' | 'orange' | 'grey' | 'grey-light' | 'grey-dark' | 'green' | 'brown' | 'silver';
    variant?: 'normal' | 'page-inner' | 'page-inner-padded' | 'page-inner-with-breadcrumb' | 'home';
    element?: 'div' | 'nav' | 'header' | 'footer' | 'section';
} & HTMLAttributes<any>;

export function Wrapper(props: WrapperProps) {
    const { bg, children, className, variant, element, ...restProps } = props;
    const classesString = classnames({
        [classes.root]: true,
        [classes.bgWhite]: bg === 'white',
        [classes.bgOrange]: bg === 'orange',
        [classes.bgGrey]: bg === 'grey',
        [classes.bgGreyLight]: bg === 'grey-light',
        [classes.bgGreyDark]: bg === 'grey-dark',
        [classes.bgBrown]: bg === 'brown',
        [classes.bgGreen]: bg === 'green',
        [classes.bgSilver]: bg === 'silver',
        [classes.variantPageInner]: variant === 'page-inner',
        [classes.variantPageInnerBreadcrumb]: variant === 'page-inner-with-breadcrumb',
        [classes.variantPageInnerPadded]: variant === 'page-inner-padded',
        [classes.variantHome]: variant === 'home',
        [className || '']: true,
    });

    if (element === 'nav') {
        return (
            <nav className={classesString} {...restProps}>
                {children}
            </nav>
        );
    }
    if (element === 'header') {
        return (
            <header className={classesString} {...restProps}>
                {children}
            </header>
        );
    }
    if (element === 'footer') {
        return (
            <footer className={classesString} {...restProps}>
                {children}
            </footer>
        );
    }

    return (
        <div className={classesString} {...restProps}>
            {children}
        </div>
    );
}

Wrapper.defaultProps = {
    variant: 'normal',
    element: 'div',
};
