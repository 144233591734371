import React, { PureComponent } from 'react';
import classnames from 'classnames';

import classes from './Header.scss';

// const DESK = require('../../../static/img/logo-header-desktop.svg');
// const MOB = require('../../../static/img/logo-header-mobile.svg');
let loader;

if (RENDER_ENV_SERVER || RENDER_ENV_STORYBOOK) {
    loader = require('raw-loader!../../../static/img/logo.txt').default;
} else {
    loader = document.getElementById('inline-svg-logo')?.innerHTML;
}

export class Logo extends PureComponent<{ variant: 'normal' | 'checkout' }> {
    render() {
        return (
            <div
                className={classnames({
                    [classes.headerLogo]: true,
                    [classes.headerLogoCheckout]: this.props.variant === 'checkout',
                })}
                id="inline-svg-logo"
                dangerouslySetInnerHTML={{ __html: loader }}
            />
        );
    }
}
