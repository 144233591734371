import React from 'react';

type NostoPageTypeProps = {
    pageType: string;
};

export const NostoPageType = (props: NostoPageTypeProps) => {
    // Return the page type as a div for Nosto to track
    if (props.pageType) {
        return (
            <div className="nosto_page_type" style={{ display: 'none' }}>
                {props.pageType}
            </div>
        );
    }

    // Return nothing as a fallback
    return null;
};

export default NostoPageType;
