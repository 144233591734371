import React, { useEffect, ImgHTMLAttributes, useRef, HTMLAttributes } from 'react';
import lozad from 'lozad';

export function useLazyRef(ref: React.RefObject<any>) {
    useEffect(() => {
        const domNode = ref.current;
        const observer = lozad(domNode);
        observer.observe();
        return () => {
            if (observer.observer && domNode) {
                observer.observer.unobserve(domNode);
            }
        };
    }, [ref]);
}

export function LazyImg(props: ImgHTMLAttributes<any>) {
    const { src, ...rest } = props;
    const ref = useRef<HTMLImageElement | null>(null);
    useLazyRef(ref);
    return <img data-src={src} {...rest} ref={ref} data-lazy-load />;
}

export function LazyPicture(props: HTMLAttributes<any>) {
    const ref = useRef<HTMLPictureElement | null>(null);
    useLazyRef(ref);
    return (
        <picture ref={ref} {...props}>
            {props.children}
        </picture>
    );
}

export function LazyBackground(props: { src: string } & HTMLAttributes<any>) {
    const { src, ...rest } = props;
    const ref = useRef<HTMLDivElement | null>(null);
    useLazyRef(ref);
    return (
        <div ref={ref} data-background-image={src} {...rest}>
            &nbsp;
        </div>
    );
}
