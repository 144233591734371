import { map, mapTo, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { defer, Observable } from 'rxjs';
import { Actions } from '@wearejh/m2-pwa-user/lib/user.actions';
import { cartItems, cartVariantAndId } from '@wearejh/m2-pwa-cart-gql/lib/utils/stateObservables';
import { fetchMineCartId } from '@wearejh/m2-pwa-cart-gql/lib/utils/fetchMineCartId';
import { CartMsg } from '@wearejh/m2-pwa-cart-gql/lib';
import { EpicDeps } from '@wearejh/m2-pwa-engine/lib/types';
import { ApolloClient } from 'apollo-client';

import { mergeCarts, mergeCartsVariables } from 'src/features/cart/queries/__generated__/mergeCarts';
import mergeCartMutation from 'src/features/cart/queries/mergeCarts.graphql';
import { Deps } from 'src/types/global-types';

/**
 * Listen for the user signing in
 *
 * When that happens, attempt to refresh the cart
 *
 * @param action$
 * @param state$
 * @param deps
 */
export function userSignedInReaction(action$: Observable<any>, state$: Observable<any>, deps: Deps) {
    return action$.pipe(
        ofType<Actions>('User.SignInSuccess'),
        withLatestFrom(cartItems(state$), cartVariantAndId(state$)),
        mergeMap(([, items, [, cartId]]) => {
            /**
             * If guest cart has 0 items, always refresh the cart from the user
             */
            const before = items.length === 0 ? fetchMineCartId(deps) : mergeGuestAndAccountCarts(String(cartId), deps);

            /**
             * If there ARE items in the guest cart,
             * we'll call cart merge by sending the previous cart ID
             */
            return before.pipe(map((cartId) => CartMsg('Cart.Fetch', { cartId })));
        }),
    );
}

function mergeGuestAndAccountCarts(cartId: string, deps: EpicDeps): Observable<string> {
    return fetchMineCartId(deps).pipe(
        switchMap((destinationId) => {
            return mergeCartAjax(cartId, destinationId, deps).pipe(mapTo(destinationId));
        }),
    );
}

function mergeCartAjax(cartId: string, destinationId: string, deps: EpicDeps): Observable<string> {
    const client = deps.client as ApolloClient<any>;
    return defer(() => {
        return client.mutate<mergeCarts, mergeCartsVariables>({
            mutation: mergeCartMutation,
            variables: {
                cartId: cartId,
                destinationId: destinationId,
            },
            fetchPolicy: 'no-cache',
        });
    }).pipe(map((x) => x.data?.mergeCarts.id || 'unknown'));
}
