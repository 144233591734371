require('intersection-observer');
if (process.env.NODE_ENV === 'development') {
    // const { inspect } = require('@xstate/inspect');
    // inspect({ iframe: false, url: 'https://statecharts.io/inspect' });
    require('preact/debug');
}
import React from 'react';
import './scss/index.scss';
import { hydrate } from 'react-dom';
import { userRegister } from '@wearejh/m2-pwa-user';
import { readJson } from '@wearejh/m2-pwa-engine/lib/utils/readJson';
import { HelmetProvider } from 'react-helmet-async';
import { initBrowserApollo, APP_ENV_STATE_ID } from '@wearejh/m2-pwa-engine/lib/browser/initBrowserApollo';
import { createHttpLink } from 'apollo-link-http';

import * as UNION_AND_FRAGMENTS from 'src/queries/__global_generated__/UNION_AND_INTERFACES.json';

import { outOfBoundsErrorLink } from './components/CategoryListingPage/links/productList.links';
import { suppressGqlErrors } from './components/Banner/links/missing-cms.link';
import { cartRegister } from './features/cart/cart.register';
import { appRegister } from './features/app/app.register';
import { initialState } from './features/app/app.reducer';
import { ErrorComponent } from './components/Error/ErrorComponent';
import { NotFound } from './components/NotFound/NotFound';
import { paths, text, contact, socialLinks } from './config.json';
import urlResolver from './queries/urlResolver.graphql';
import { knownRoutes } from './routes';
import { apiUrl } from './util/apiUrl';
import { AppShell } from './components/AppShell/AppShell';
import getCartQuery from './queries/getCart.graphql';

const { links, ...appFeature } = appRegister();

/**
 * The env vars will come from the in-page JSON
 */
const appEnv = readJson(APP_ENV_STATE_ID);

const apiBase = location.origin + '/graphql';

let httpLink = createHttpLink({
    uri: apiBase,
    useGETForQueries: true,
});

/**
 * If we're in a testing environment, use a different
 * HTTP link to resolve GQL queries
 */
if (process.env.NODE_ENV === 'production') {
    /** noop **/
} else {
    if (window.Cypress) {
        httpLink = require('./features/cypress/http-link').createHttpLink({
            uri: apiBase,
            useGETForQueries: true,
        });
    }
}

// eslint-disable-next-line prefer-const
const createApp = initBrowserApollo({
    unionAndInterfaceTypes: UNION_AND_FRAGMENTS,
    urlResolver,
    env: appEnv,
    knownRoutes,
    deps: {
        apiUrl,
        paths,
        text,
        contact,
        socialLinks,
        pbExtractHtml: (s) => s,
        restHeaders: (incoming) => {
            const outgoing = {
                ...incoming,
            };
            return outgoing;
        },
        queries: {
            getCart: getCartQuery,
        },
    },
    asyncLoader: (componentName: string) => import(/* webpackChunkName: "[request]_root" */ `./pages/${componentName}`),
    resolveWeakLoader: (componentName: string) => (require as any).resolveWeak(`./pages/${componentName}`),
    features: [appFeature, userRegister(), cartRegister()],
    links: [suppressGqlErrors, outOfBoundsErrorLink, ...links, httpLink],
    initialState: {
        app: {
            ...initialState,
            online: window.navigator.onLine,
            renderEnv: 'browser',
        },
    },
    components: {
        ErrorComponent: ErrorComponent,
        OfflineComponent: ErrorComponent,
        OutdatedComponent: NotFound,
        NotFoundComponent: NotFound,
    },
});

const { App, Async } = createApp();

hydrate(
    <App>
        <HelmetProvider>
            <AppShell>{Async}</AppShell>
        </HelmetProvider>
    </App>,
    document.getElementById('root'),
);
