import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';

import classes from './Skeleton.scss';

type SkeletonBlockProps = {
    className?: string;
} & HTMLAttributes<any>;

export function SkeletonBlock(props: SkeletonBlockProps) {
    const { className, ...restProps } = props;
    return (
        <div
            className={classnames({
                [classes.skeletonBlock]: true,
                [className || '']: true,
            })}
            {...restProps}
        />
    );
}
