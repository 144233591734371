import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { skip, subscribeOn, tap } from 'rxjs/operators';
import { asyncScheduler } from 'rxjs';
import { useSelector } from 'react-redux';

import { useClickOutside } from 'src/hooks/useClickOutside';
import { useHistoryLocationObservable } from 'src/hooks/router-hooks';
import { useBreakpointMatch, useRenderEnv } from 'src/hooks/useBreakpoint';
import { Delay } from 'src/components/Helpers/Delay';
import { StaticSpinner } from 'src/components/Layout/Spinner';
import { StoreState } from 'src/types/global-types';

import { Container } from '../Layout';
import { HeaderContext } from '../Header/Header';

import classes from './Nav.scss';

let NavQueryImport = React.lazy(() => import('./NavQuery'));

if (RENDER_ENV_SERVER) {
    NavQueryImport = require('./NavQuery').default;
}

export const Nav = React.memo(function Nav() {
    const { showNav, setShowNav } = useContext(HeaderContext);
    const scrollPosition = useRef<number>(0);

    /**
     * The package `scroll-lock` is used to prevent the body from scrolling
     * However it does not work for multi level modals, it is only possible where one child is scrollable.
     * So we have to use a custom solution
     */
    useEffect(() => {
        const body = document?.querySelector('body');
        scrollPosition.current = window.pageYOffset;
        if (showNav && body) {
            body.style.overflow = 'hidden';
            body.style.position = 'fixed';
            body.style.top = `-${scrollPosition.current}px`;
            body.style.width = '100%';
        } else {
            if (body) {
                body.style.removeProperty('overflow');
                body.style.removeProperty('position');
                body.style.removeProperty('top');
                body.style.removeProperty('width');
                window.scrollTo(0, scrollPosition.current);
            }
        }
    }, [showNav]);

    // Get the current modal state from redux store
    const { isActive: isModalActive } = useSelector((state: StoreState) => state.app.modalState);

    const outerRef = useRef<HTMLDivElement | null>(null);
    const { isServer, isBrowser } = useRenderEnv();
    const { isMob, isDesk } = useBreakpointMatch();
    const closeNav = useCallback(() => setShowNav(false), [setShowNav]);

    /**
     * Only enabled 'click outside' if mobile nav is open
     * and no modal is currently active.
     */
    const isClickOutsideEnabled = showNav && !isModalActive;
    useClickOutside(outerRef, isClickOutsideEnabled, closeNav);

    const location$ = useHistoryLocationObservable();

    useEffect(() => {
        const sub = location$
            .pipe(
                skip(1),
                subscribeOn(asyncScheduler),
                tap(() => closeNav()),
            )
            .subscribe();
        return () => sub.unsubscribe();
    }, [closeNav, location$]);

    const [overflow, setOverflow] = useState(true);

    return (
        <nav
            data-nav-top
            ref={outerRef}
            data-nav-active={String(showNav)}
            data-nav-overflow={String(overflow)}
            className={classes.nav}
        >
            <Container className={classes.navContainer}>
                <section className={classes.navHolder}>
                    {isServer && <NavQueryImport />}
                    {isMob && isBrowser && showNav && <NavQueryLoaderMob setOverflow={setOverflow} />}
                    {isDesk && isBrowser && <NavQueryLoaderDesk />}
                </section>
            </Container>
        </nav>
    );
});

const LOADER = (
    <div className={classes.loader}>
        <StaticSpinner />
    </div>
);

const LazyNavHeader = React.lazy(() => import('./NavHeader'));
function NavQueryLoaderMob(props: { setOverflow: (value: boolean) => void }) {
    return (
        <Delay delay={300}>
            <React.Suspense fallback={LOADER}>
                <LazyNavHeader />
                <NavQueryImport setOverflow={props.setOverflow} />
            </React.Suspense>
        </Delay>
    );
}

function NavQueryLoaderDesk() {
    return (
        <React.Suspense fallback={LOADER}>
            <NavQueryImport />
        </React.Suspense>
    );
}
