import { PostData, PostDataRaw } from 'src/wordpress/types';

export const normalizePostsArray = (posts?: PostDataRaw) => {
    return posts?.map((post): PostData => {
        const imageSizes = post?.featuredImage?.node?.mediaDetails?.sizes?.sort((a, b) => {
            return Number(a.width) - Number(b.width);
        });
        return {
            postTitle: post?.title ?? undefined,
            postExcerpt: post?.excerpt ?? undefined,
            postSlug: post?.slug ?? undefined,
            postModified: post?.modifiedGmt ?? undefined,
            postDate: post?.dateGmt ?? undefined,
            categories: post?.categories ?? undefined,
            featuredImage: {
                sizes: imageSizes ?? undefined,
                fullURL: post?.featuredImage?.node?.fullURL ?? undefined,
                alt: post?.featuredImage?.node?.altText ?? undefined,
            },
        };
    });
};

export default normalizePostsArray;
