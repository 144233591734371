import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { useDeps } from 'src/hooks/useDeps';
import { usePathname } from 'src/hooks/router-hooks';
import { paths } from 'src/config.json';

import { Container } from '../Layout/Container';
import { Wrapper } from '../Layout';
import { jhMenu, jhMenuVariables } from '../../queries/__generated__/jhMenu';
import jhMenuQuery from '../../queries/jhMenu.graphql';

import classes from './Footer.scss';
import { FooterCta } from './FooterCta';
import { FooterMenu, FooterMenus } from './FooterMenus';
import { FooterLegal } from './FooterLegal';

export const Footer = React.memo(function Footer() {
    const { data, error, loading } = useQuery<jhMenu, jhMenuVariables>(jhMenuQuery, {
        variables: { identifier: 'footer-links' },
        ssr: useDeps().env.SSR_GQL,
    });

    if (error) {
        console.error('could not fetch Footer menus', error);
        return null;
    }
    if (loading) return null;
    if (!data || !Array.isArray(data.jhMenu)) {
        console.error('jhMenu was missing', data);
        return null;
    }

    return (
        <Wrapper className={classes.footer} element="footer" bg="grey">
            <Container>
                <FooterCta />
                <FooterMenus menus={data.jhMenu as FooterMenu[]} />
            </Container>
        </Wrapper>
    );
});

export function FooterWrap(props: { variant?: 'checkout' | 'normal' }) {
    const pathname = usePathname();
    const isCheckout =
        props.variant === 'checkout' ||
        pathname.startsWith('/checkout') ||
        pathname.indexOf(paths.checkout.adyenHPPRedirect) === 0;

    return (
        <div>
            {!isCheckout && <Footer />}
            <Wrapper bg="silver">
                <Container>
                    <FooterLegal variant={isCheckout ? 'checkout' : 'normal'} />
                </Container>
            </Wrapper>
        </div>
    );
}
