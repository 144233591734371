import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Container, Wrapper } from 'src/components/Layout';
import { Svg } from 'src/components/Svg/Svg';
import { SvgIconNames } from 'src/components/Svg/icons-types';

import classes from './Usps.scss';

type Props = Record<string, unknown>;

export const Usps: React.FC<Props> = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const data: { title: string; desc: string; icon: SvgIconNames; link: string }[] = [
        {
            title: 'Free Same Day Click & Collect',
            desc: '',
            icon: 'click-collect',
            link: '/click-collect',
        },
        {
            title: 'Delivery',
            desc: 'Information',
            icon: 'delivery',
            link: '/delivery-information',
        },
        {
            title: '35 Stores Nationwide',
            desc: 'Open 7 days a week',
            icon: 'place',
            link: '/storelocator',
        },
        {
            title: 'Free and easy returns',
            desc: 'for up to 30 days',
            icon: 'returns',
            link: '/return-policy',
        },
    ];

    const totalCount = data.length;
    const position = (slideIndex * 100) / totalCount;

    return (
        <Wrapper className={classes.usp} bg="grey-light" element="section">
            <Container className={classes.uspContainer}>
                <ul className={classes.uspList} style={{ transform: `translateX(-${position}%)` }}>
                    {data.map((item, index) => {
                        return (
                            <li key={index} className={classes.uspItem}>
                                <Link to={item.link} className={classes.uspNoDecoration}>
                                    {item.icon && (
                                        <>
                                            <figure className={classes.uspItemIcon}>
                                                <Svg name={item.icon as SvgIconNames} />
                                            </figure>
                                            <span>
                                                <b>{item.title}</b> {item.desc}
                                            </span>
                                        </>
                                    )}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
                <button
                    disabled={slideIndex === 0}
                    onClick={() => {
                        setSlideIndex(slideIndex - 1);
                    }}
                    className={classes.uspPrev}
                    type="button"
                >
                    <Svg name="arrow-left" />
                </button>

                <button
                    disabled={slideIndex === totalCount - 1}
                    onClick={() => {
                        setSlideIndex(slideIndex + 1);
                    }}
                    className={classes.uspNext}
                    type="button"
                >
                    <Svg name="arrow-right" />
                </button>
            </Container>
        </Wrapper>
    );
};
