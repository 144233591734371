import { Message, MessageType } from '@wearejh/m2-pwa-engine/lib/types';

import { UiMessage } from 'src/components/Messages/Messages';

export function errorMessage(text: string): Message {
    return { type: MessageType.Error, text };
}

export function successMessage(text: string): Message {
    return { type: MessageType.Success, text };
}

export function warningMessage(text: string): Message {
    return { type: MessageType.Warning, text };
}

export function uiErrorMessage(title: string, text: string): UiMessage {
    return { type: MessageType.Error, text, title };
}
