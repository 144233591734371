import { ProductListItem } from 'src/components/CategoryListingPage/utils/productListItem';
import { ProductPrice } from 'src/types/global-types';

export const formatProducts = (products: any): ProductListItem[] => {
    if (!Array.isArray(products)) return [];

    const cleanedNostoProducts: ProductListItem[] = [];

    products.map((product) => {
        const urlWithoutDomain = product.url.split('woodies.ie')?.[1];

        const regularPrice = product.list_price;
        const currentPrice = product.price;

        const price: ProductPrice = {
            price: currentPrice,
            price_incl: currentPrice,
        };

        if (currentPrice !== regularPrice) {
            price.regular_price = regularPrice;
            price.discount = regularPrice - currentPrice;
        }

        const formattedProduct = {
            id: product.product_id ?? '',
            name: product.name ?? '',
            url: urlWithoutDomain ?? product.url ?? '/',
            price,
            small_image: {
                label: product.name ?? '',
                url: product.image_url ?? '',
            },
            click_collect: false,
            delivery: false,
            labels: [],
        };

        cleanedNostoProducts.push(formattedProduct);
    });

    return cleanedNostoProducts;
};

export default formatProducts;
