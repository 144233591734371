import React from 'react';
import { decode } from 'html-entities';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { FeaturedImage } from 'src/wordpress/components/FeaturedImage';
import { SingleCategoryRaw, SingleCategory } from 'src/wordpress/types';

import classes from './PostCard.module.scss';

export type PostCardProps = {
    variant?: 'hero' | 'normal';
    featuredImage?: any;
    postTitle: string;
    postExcerpt: string;
    categories: SingleCategoryRaw;
    postSlug: string;
};

export const PostCard = (props: PostCardProps) => {
    if (props?.variant === 'hero') {
        return (
            <div
                className={classnames({
                    [classes.PostCard]: true,
                    [classes.PostCard__Hero]: props?.variant === 'hero',
                })}
            >
                <FeaturedImage
                    sizes={props?.featuredImage?.sizes}
                    variant="card-hero"
                    fullURL={props?.featuredImage?.fullURL}
                    alt={props?.featuredImage?.alt}
                />

                <div className={classes.PostCard__PostData}>
                    {props?.categories && (
                        <ul className={classes.PostCard__CategoryList}>
                            {props?.categories?.nodes?.map((category: SingleCategory, index) => {
                                return (
                                    <li
                                        className={classes.PostCard__PostCategoryHero}
                                        key={`${category?.name}-${index}`}
                                    >
                                        {decode(category?.name) +
                                            (index < props.categories.nodes.length - 1 ? ',' : '')}
                                    </li>
                                );
                            })}
                        </ul>
                    )}

                    <h2 className={classes.PostCard__PostTitleHero}>{decode(props.postTitle)}</h2>

                    <div
                        className={classes.PostCard__PostExcerpt}
                        dangerouslySetInnerHTML={{ __html: decode(props.postExcerpt) }}
                    />

                    <Link to={'/community/' + props.postSlug} className={`button ${classes.PostCard__ReadMore}`}>
                        <span>Read More</span>
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <Link to={`/community/${props.postSlug}`} className={classes.PostCard}>
            <FeaturedImage
                sizes={props?.featuredImage?.sizes}
                fullURL={props?.featuredImage?.fullURL}
                alt={props?.featuredImage?.alt}
            />

            {props?.categories && (
                <ul className={classes.PostCard__CategoryList}>
                    {props?.categories?.nodes?.map((category: SingleCategory, index) => {
                        return (
                            <li className={classes.PostCard__PostCategoryHero} key={`${category?.name}-${index}`}>
                                {category?.name + (index < props.categories.nodes.length - 1 ? ',' : '')}
                            </li>
                        );
                    })}
                </ul>
            )}

            <h2 className={classes.PostCard__PostTitle}>{props.postTitle}</h2>
        </Link>
    );
};

PostCard.defaultProps = {
    variant: 'normal',
};

export default PostCard;
