import React from 'react';
import { Link } from 'react-router-dom';

import { Logo } from './Logo';
import classes from './Header.scss';

export const HeaderLogo = React.memo(function HeaderLogo() {
    return (
        <Link to="/" className={classes.headerLogoLink} data-test-id="logo">
            <Logo variant="normal" />
        </Link>
    );
});
