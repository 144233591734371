import React from 'react';

import { useCleanMediaUrl } from 'src/wordpress/hooks';

import classes from './FeaturedImage.module.scss';

export type FeaturedImageProps = {
    fullURL?: string;
    sizes?: {
        sourceUrl?: string;
        name?: string;
        width?: string;
    }[];
    classNames?: string;
    alt?: string;
    variant?: 'card' | 'card-hero' | 'post-hero' | 'category-card';
};

export type FeaturedImageSourceProps = {
    sourceUrl?: string;
    mediaWidth?: string;
};

const FeaturedImageSource = (props: FeaturedImageSourceProps) => {
    const { cleanMediaUrl } = useCleanMediaUrl(props.sourceUrl);
    return <source srcSet={cleanMediaUrl} media={`(max-width: ${props.mediaWidth}px)`} />;
};

export const FeaturedImage = (props: FeaturedImageProps) => {
    const { cleanMediaUrl } = useCleanMediaUrl(
        props?.fullURL ? props?.fullURL : props?.sizes?.[props?.sizes.length - 1]?.sourceUrl,
    );
    return (
        <div className={classes.FeaturedImage__Wrapper} data-variant={props.variant}>
            <picture className={classes.FeaturedImage}>
                {props?.sizes?.map((size, index) => (
                    <FeaturedImageSource
                        key={`${size.name}-${size.width}-${index}`}
                        sourceUrl={size.sourceUrl}
                        mediaWidth={size.width}
                    />
                ))}
                {props?.sizes && props?.sizes.length > 0 && <img src={cleanMediaUrl} alt="" />}
            </picture>
        </div>
    );
};

FeaturedImage.defaultProps = {
    variant: 'card',
};

export default FeaturedImage;
