import React from 'react';
import classnames from 'classnames';

import { SvgIconNames } from './icons-types';

type SvgProps = {
    name: SvgIconNames;
    color?: 'blue' | 'primary-light' | 'white' | 'black' | 'secondary' | 'success' | 'failure' | 'grey' | 'core-green';
    className?: string;
};

export function Svg(props: SvgProps) {
    const { name, color, className, ...rest } = props;
    return (
        <svg
            className={classnames({
                ['svg']: true,
                [props.color ? `color--${props.color}` : '']: true,
                [props.className || '']: true,
            })}
            {...rest}
        >
            <use xlinkHref={`#svg-${props.name}`} />
        </svg>
    );
}
