import React from 'react';

import { PostCard } from '../PostCard';

import classes from './PostListGrid.module.scss';

export type PostListGridProps = {
    variant?: 'frontpage' | 'archive' | 'related-posts' | 'plp-pdp';
    posts?: any[] | null;
};

export const PostListGrid = (props: PostListGridProps) => {
    return (
        <div className={classes.PostListGrid} data-variant={props?.variant}>
            {props?.posts?.map((post, index) => {
                if (index === Number(0) && props?.variant === 'frontpage') {
                    return (
                        <PostCard
                            key={`${post.postTitle}-${post.slug}-${index}`}
                            featuredImage={post.featuredImage}
                            postTitle={post.postTitle}
                            postExcerpt={post.postExcerpt}
                            postSlug={post.postSlug}
                            variant="hero"
                            categories={post?.categories}
                        />
                    );
                }

                return (
                    <PostCard
                        key={`${post.postTitle}-${post.slug}-${index}`}
                        featuredImage={post.featuredImage}
                        postTitle={post.postTitle}
                        postExcerpt={post.postExcerpt}
                        postSlug={post.postSlug}
                        categories={post?.categories}
                    />
                );
            })}
        </div>
    );
};

PostListGrid.defaultProps = {
    variant: 'archive',
};

export default PostListGrid;
