import React, { useCallback, useEffect, useState } from 'react';
import { of } from 'rxjs';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

import { usePathnameObservable } from 'src/hooks/router-hooks';
import { SearchLoader } from 'src/components/Algolia/SearchDropdown/SearchLoader';
import { useDeps } from 'src/hooks/useDeps';
import { useBreakpointMatch } from 'src/hooks/useBreakpoint';
import { Nav } from 'src/components/Nav/Nav';
import { StoreState } from 'src/types/global-types';
import { AppMsg } from 'src/features/app/app.actions';
import { useSmoothScrollToSelector } from 'src/hooks/useSmoothScrollToSelector';

import { Container, Wrapper } from '../Layout';

import classes from './Header.scss';
import { HeaderControls } from './HeaderControls';
import { HeaderLogo } from './HeaderLogo';

type HeaderProps = {
    isOpen: boolean;
    isDesk: boolean;
    isMob: boolean;
};

export const HeaderContext = React.createContext({
    showNav: false,
    showSearch: false,
    pathname$: of(''),
    isDesk: false,
    isMob: false,
    goToAccount: () => {
        console.error('goToAccount not implemented');
    },
    setShowNav: (..._args: any[]) => {
        console.error('unable to open Navigation, `setShowNav` not implemented');
    },
    setShowSearch: (..._args: any[]) => {
        console.error('unable to open Search');
    },
});

export const Header: React.FC<HeaderProps> = React.memo((props) => {
    return (
        <div>
            {(props.isOpen || props.isDesk) && <div className={classes.overlay}> </div>}
            <Wrapper
                element="header"
                bg="grey"
                data-test-id="DefaultHeader"
                className={classnames({
                    [classes.header]: true,
                    [classes.headerWithOverlay]: props.isOpen,
                })}
            >
                <Container className={classes.headerContainer}>
                    <div className={classes.headerLeft}>
                        <HeaderLogo />
                        {props.isDesk && <SearchLoader />}
                    </div>
                    <div className={classes.headerRight}>
                        <HeaderControls />
                    </div>
                </Container>
                {props.children}
                {props.isMob && <SearchLoader mobOnly={true} />}
            </Wrapper>
        </div>
    );
});

export const HeaderProvider = React.memo(function HeaderProvider() {
    // Get the current modal state from redux store
    const { openNav } = useSelector((state: StoreState) => state.app);
    const scrollTo = useSmoothScrollToSelector();

    // const [showNav, setShowNav] = useState(openNav);
    const [showSearch, setShowSearch] = useState(false);
    const pathname$ = usePathnameObservable();
    const { isDesk, isMob } = useBreakpointMatch();

    /**
     * A callback for returning to an account screen
     */
    const { paths, replace } = useDeps();
    const dispatch = useDispatch();
    const goToAccount = useCallback(() => {
        dispatch(replace(paths.customer.account));
    }, [dispatch, paths, replace]);

    useEffect(() => {
        const $scrollableSearch = document.querySelector(`[data-scrolllock-search]`);

        if (isDesk) return;

        if (showSearch) {
            disablePageScroll($scrollableSearch);
        }
        if (openNav) {
            scrollTo('[data-test-id="DefaultHeader"]');
        }
        return () => {
            enablePageScroll($scrollableSearch);
        };
    }, [isDesk, openNav, showSearch, scrollTo]);

    /**
     * Toggle nav open status in redux
     */
    const setShowNav = useCallback((newState: boolean) => dispatch(AppMsg('App.OpenNav', newState)), [dispatch]);

    return (
        <HeaderContext.Provider
            value={{
                showNav: openNav,
                setShowNav,
                showSearch: showSearch,
                setShowSearch,
                pathname$,
                goToAccount,
                isDesk,
                isMob,
            }}
        >
            <Header isOpen={isDesk ? false : openNav || showSearch} isDesk={isDesk} isMob={isMob}>
                <Nav />
            </Header>
        </HeaderContext.Provider>
    );
});
