import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCustomerStatus } from '@wearejh/m2-pwa-user/lib/hooks/useCustomerStatus';

import { usePathname } from 'src/hooks/router-hooks';
import { useDeps } from 'src/hooks/useDeps';
import { AppMsg } from 'src/features/app/app.actions';

import { Svg } from '../Svg/Svg';

import classes from './HeaderControls.scss';

export const AccountIcon = React.memo(function AccountIcon() {
    const { isSignedIn } = useCustomerStatus();
    return (
        <div>
            {!isSignedIn && <SignedOut />}
            {isSignedIn && (
                <Link
                    className={classnames({
                        [classes.headerControl]: true,
                        [classes.headerControlUser]: true,
                    })}
                    to="/customer/account"
                >
                    <Svg name="account" />
                    <span className={classes.headerControlContent}>Account</span>
                </Link>
            )}
        </div>
    );
});

/**
 * This is extracted so that we can change functionality
 * based on which page the user is on.
 *
 * If the user is on ANY customer page, we will NOT show the modals, ever
 *
 */
function SignedOut() {
    const pathname = usePathname();
    const reduxDispatch = useDispatch();
    const { push, paths } = useDeps();
    const onClick = useCallback(() => {
        if (pathname.indexOf('/customer/') === 0) {
            reduxDispatch(push(paths.customer.login));
        } else {
            reduxDispatch(AppMsg('App.Modal.Open.SignIn'));
        }
    }, [pathname, paths, reduxDispatch, push]);
    return (
        <button
            className={classnames({
                [classes.headerControl]: true,
                [classes.headerControlUser]: true,
            })}
            data-test-id="AccountIcon_login"
            onClick={onClick}
            type="button"
        >
            <Svg name="account" />
            <span className={classes.headerControlContent}>Log In</span>
        </button>
    );
}
