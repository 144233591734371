import React from 'react';
import { Link } from 'react-router-dom';

import { Logo } from '../Header/Logo';

import classes from './FooterCta.scss';
import { FooterFeatures } from './FooterFeatures';

export const FooterCta = React.memo(function FooterCta() {
    return (
        <div className={classes.footerCta}>
            <Link to="/" className={classes.footerCtaLogoLink}>
                <Logo variant="normal" />
            </Link>
            <FooterFeatures />
        </div>
    );
});
