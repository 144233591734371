/**
 * Klarna On-Screen Placement Component
 *
 * Use this component to render out a placement for Klarna's OSM service.
 * @see https://docs.klarna.com/on-site-messaging/
 */
import { createElement, useMemo } from 'react';

import { useDeps } from 'src/hooks/useDeps';

import type { KlarnaPlacementProps } from './types';

/**
 * This is the threshold at which the messaging moves on the page. For example, if the product price is above
 * €50.00 then we display at the bottom of the product description, if it's over we put it above the product title.
 */
export const KLARNA_PRICE_THRESHOLD = 35;

// Use this line if testing the playground of Klarna. Klarna playground doesn't include upsell messaging, so you need the
// higher number to see the correct placements on PDP
// export const KLARNA_PRICE_THRESHOLD = 75;

export const KlarnaPlacement = (props: KlarnaPlacementProps) => {
    const { KLARNA_ENABLED } = useDeps().env;

    /**
     * Format the price we are given into the required format for Klarna.
     * Klarna requires that prices are provided in minor units.
     * E.g. 20.00 becomes 2000
     *
     * The price here works either on the Magento Price object or just a single price as a number
     */
    const formattedPrice = typeof props?.klarnaPrice === 'number' ? props?.klarnaPrice : props?.klarnaPrice?.price_incl;

    return useMemo(() => {
        if (!KLARNA_ENABLED) return null;
        /**
         * We have to use Reacts `createElement` to create the correct element for Klarna. This helps avoid Typescript
         * errors.
         */
        return createElement('klarna-placement', {
            'data-key': props.klarnaKey ?? 'top-strip-promotion-auto-size',
            'data-locale': 'en-IE',
            'data-purchase-amount': formattedPrice ? Number(formattedPrice.toFixed(2)?.replace('.', '')) : null,
            'data-theme': props.klarnaTheme ?? null,
            className: props?.classNames?.join(' ') ?? undefined,
        });
    }, [KLARNA_ENABLED, props.klarnaKey, props.klarnaTheme, props?.classNames, formattedPrice]);
};
