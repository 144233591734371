import React, { useCallback, useEffect, useRef } from 'react';
import { merge, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { shallowEqual, useSelector } from 'react-redux';

import { useDeps } from 'src/hooks/useDeps';
import { StoreState } from 'src/types/global-types';
import { useSubject } from 'src/hooks/useSubject';
import { Button } from 'src/components/Button/Button';
import { fireGTMEvent } from 'src/features/analytics/hooks/useGtm';

import classes from './SearchLayout.scss';

type Props = {
    trigger: Subject<string>;
};

export const navOpenSelector = (s: StoreState) => s.app.openNav;

export function useObservableSelector<T extends (...args: any[]) => any>(selector): Subject<ReturnType<T>> {
    const result = useSelector(selector, shallowEqual);
    const sub = useSubject<ReturnType<any>>({ name: 'useObservableSelector' });
    useEffect(() => {
        sub.next(result);
    }, [result, sub]);
    return sub;
}

function SearchForm(props: Props) {
    const { trigger } = props;
    const { historyEvents$ } = useDeps();
    const navOpen$ = useObservableSelector(navOpenSelector);
    const ref = useRef<HTMLInputElement>();

    const onChange = useCallback(
        (event) => {
            trigger.next(event.currentTarget.value);
        },
        [trigger],
    );

    useEffect(() => {
        const pathChanges = historyEvents$;
        const navOpens = navOpen$.pipe(filter(Boolean));
        /**
         * All the events that should cause the search to be closed
         */
        const searchClose = merge(pathChanges, navOpens)
            .pipe(
                tap(() => {
                    if (ref.current) {
                        ref.current.value = '';
                    }
                    trigger.next('');
                }),
            )
            .subscribe();
        return () => {
            searchClose.unsubscribe();
        };
    }, [navOpen$, historyEvents$, ref, trigger]);

    const gtmSearch = useCallback(() => {
        fireGTMEvent({ event: 'search', search_term: (ref.current && ref.current.value) || '' });
    }, []);

    return (
        <form
            className={classes.searchForm}
            action="/catalogsearch/results"
            method="GET"
            autoComplete="off"
            data-test-id="SearchForm"
            onSubmit={gtmSearch}
        >
            <input
                className={classes.searchInput}
                id="search"
                ref={ref as any}
                name="query"
                type="search"
                onChange={onChange}
                aria-label="Search Box"
                placeholder="Search"
                data-test-id="SearchInput"
            />
            <Button className={classes.searchButton} type="submit" variant="primary" aria-label="Search">
                Search
            </Button>
        </form>
    );
}

export const CustomSearchForm = SearchForm;
