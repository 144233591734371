import React from 'react';
import classnames from 'classnames';

import { NostoPlacementType, NostoProductData } from 'src/components/Nosto/types';
import { formatProducts } from 'src/components/Nosto/utilities';
import NostoProductCard from 'src/components/Nosto/NostoProductCard';
import { useProductListGtm } from 'src/components/CategoryListingPage/hooks/useProductListGtm';

import classes from './styles/NostoSinglePlacement.scss';

export type NostoSinglePlacementProps = {
    placementTitle?: string;
    placementProducts?: NostoProductData[];
    placementId?: string;
    placementType?: NostoPlacementType;
    slotRef?: string;
};
export const NostoSinglePlacement = (props: NostoSinglePlacementProps) => {
    // Format the products into a more Woodie's product card friendly manner
    const products = formatProducts(props.placementProducts);

    // gtmSelectItem - fires on clicks of items
    const { gtmSelectItem } = useProductListGtm({
        products: products ?? [],
        listName: props.placementType,
        listId: props.placementType,
    });

    if (products.length > 0) {
        return (
            <div className={classnames('nosto_element', classes['nosto_element'])} id={props.placementId}>
                <h2>{props.placementTitle}</h2>
                <div className={classes['placementProductWrapper']}>
                    {products.slice(0, 4).map((product, index) => {
                        return (
                            <NostoProductCard
                                key={`${product.name}--${index}`}
                                product={product}
                                onClick={() => gtmSelectItem(index)}
                                slotRef={props.slotRef}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }

    return null;
};

export default NostoSinglePlacement;
