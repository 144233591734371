import { PageData, PageDataRaw } from '../types';

export const normalizePageData = (pageData?: PageDataRaw): PageData => {
    return {
        offsetPagination: {
            hasMore: pageData?.offsetPagination?.hasMore ?? undefined,
            hasPrevious: pageData?.offsetPagination?.hasPrevious ?? undefined,
        },
    };
};

export default normalizePageData;
