import React from 'react';
import classnames from 'classnames';

import { LazyImg } from 'src/hooks/useLazyLoad';
import { KlarnaPlacement } from 'src/components/KlarnaOSM/Klarna-Placement';

import classes from './Cards.scss';

const cards = [
    { type: 'visa', src: require(`../../../static/img/visa.svg`), alt: 'Visa' },
    { type: 'master-card', src: require(`../../../static/img/mastercard.svg`), alt: 'MasterCard' },
    { type: 'paypal', src: require(`../../../static/img/paypal.svg`), alt: 'PayPal' },
    { type: 'one4all', src: require(`../../../static/img/one4all.png`), alt: 'one4all' },
    { type: 'adyen', src: require(`../../../static/img/adyen.svg`), alt: 'Adyen' },
    { type: 'google-pay', src: require(`../../../static/img/google-pay.svg`), alt: 'Google Pay' },
    { type: 'apple-pay', src: require(`../../../static/img/apple-pay.svg`), alt: 'Apple Pay' },
];

type Props = {
    activeTypes: string[];
    centered?: boolean;
};

export function Cards(props: Props) {
    return (
        <ul
            className={classnames({
                [classes.root]: true,
                [classes.rootCentered]: props.centered,
            })}
        >
            {cards.map((card) => {
                const isActive = props.activeTypes.length === 0 || props.activeTypes.indexOf(card.type) > -1;
                return (
                    <li key={card.alt} className={classes.item} data-active={String(isActive)}>
                        <LazyImg src={card.src} alt={card.alt} />
                    </li>
                );
            })}

            <li className={classes.item} data-active="true">
                <KlarnaPlacement klarnaKey="sidebar-promotion-auto-size" />
            </li>
        </ul>
    );
}
