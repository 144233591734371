import React from 'react';
import { Link } from 'react-router-dom';

import { LazyImg } from 'src/hooks/useLazyLoad';
import { ProductMainPrice } from 'src/components/ProductDetail/ProductMainPrice';
import { imagePath } from 'src/util/formatUrl';
import { ProductListItem } from 'src/components/CategoryListingPage/utils/productListItem';

import classes from '../CategoryListingPage/ProductList.scss';

export type NostoProductCardProps = {
    product: ProductListItem;
    onClick?: () => void;
    slotRef?: string;
};

export const NostoProductCard = (props: NostoProductCardProps) => {
    return (
        <div className={classes.content} data-variant="nosto">
            <Link to={`${props.product.url}?nosto=${props.slotRef}`} className={classes.image} onClick={props.onClick}>
                <LazyImg src={imagePath(props.product.small_image.url)} alt={props.product.small_image.label} />
            </Link>
            <div className={classes.productListInfo}>
                <Link
                    to={`${props.product.url}?nosto=${props.slotRef}`}
                    className={classes.productListTitle}
                    onClick={props.onClick}
                >
                    {props.product.name}
                </Link>
                <span className={classes.productListPrice}>
                    <ProductMainPrice
                        price={{
                            discount: props.product.price.discount,
                            price_incl: props.product.price.price,
                            price: props.product.price.price,
                            regular_price: props.product.price.regular_price,
                        }}
                        vat={true}
                        layout="grid"
                    />
                </span>
            </div>
        </div>
    );
};

export default NostoProductCard;
