import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { BehaviorSubject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, skip, tap } from 'rxjs/operators';
import classnames from 'classnames';
import { useConstant } from '@wearejh/react-hooks/lib/useConstant';

import classes from 'src/components/Algolia/SearchDropdown/SearchLayout.scss';
import { HeaderContext } from 'src/components/Header/Header';
import { useClickOutside } from 'src/hooks/useClickOutside';

import { CustomSearchForm } from './SearchForm';
import { SearchPlaceholder } from './SearchPlaceholder';

export const DropdownLoaderLoader: React.FC<{ trigger: SearchTrigger }> = React.lazy(
    () => import(/* webpackChunkName:"__lazy__algolia-instant-search" */ './DropdownLoader'),
);

export type SearchTrigger = BehaviorSubject<string>;

export const SearchLoader = React.memo(function SearchLoader(props: { mobOnly?: boolean }) {
    const { showSearch, setShowSearch } = useContext(HeaderContext);

    const outerRef = useRef<HTMLDivElement | null>(null);

    const close = useCallback(() => setShowSearch(false), [setShowSearch]);

    useClickOutside(outerRef, showSearch, close);

    const sub = useConstant(() => new BehaviorSubject<string>(''));

    return (
        <div
            data-scrolllock-search
            ref={outerRef}
            className={classnames({
                [classes.search]: true,
                [classes.searchActive]: showSearch,
                [classes.searchMobOnly]: Boolean(props.mobOnly),
            })}
        >
            <CustomSearchForm trigger={sub} />
            <Wrapper trigger={sub} />
        </div>
    );
});

export const DropdownContext = React.createContext<{ setOpen?(open: boolean) }>({});

const Wrapper = React.memo((props: { trigger: SearchTrigger }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const sub = merge(props.trigger)
            .pipe(
                skip(1),
                debounceTime(100),
                distinctUntilChanged(),
                tap((searchValue) => {
                    const open = Boolean(typeof searchValue === 'string' && searchValue.trim().length > 2);
                    if (open) {
                        setOpen(true);
                        return;
                    }
                    setOpen(false);
                }),
            )
            .subscribe();
        return () => {
            sub.unsubscribe();
        };
    }, [props.trigger]);

    if (open) {
        return (
            <React.Suspense fallback={<SearchPlaceholder />}>
                <DropdownContext.Provider value={{ setOpen }}>
                    <DropdownLoaderLoader trigger={props.trigger} />
                </DropdownContext.Provider>
            </React.Suspense>
        );
    }

    return null;
});
