import React, { lazy, Suspense } from 'react';

import { Delay } from 'src/components/Helpers/Delay';

import classes from './ModalPortal.scss';
import { defaultModalPortalContextProps, ModalPortalProvider } from './ModalPortalContext';
import { ModalLoading } from './ModalLoading/ModalLoading';
import { ModalPortalContextProps } from './ModalPortal.types';

/**
 * Lazy loaded `ModalContents`
 */
const LazyModalContents = lazy(() => import('./ModalContents'));

export interface ModalPortalProps {
    /**
     * Shared context/settings used by `ModalPortal`
     * and child components.
     */
    context: ModalPortalContextProps;
}

/**
 * Default prop values used by `ModalPortal`
 */
export const defaultModalPortalProps: Pick<ModalPortalProps, 'context'> = {
    context: defaultModalPortalContextProps,
};

/**
 * Manage Modals
 */
export function ModalPortal(props: ModalPortalProps) {
    const { isActive } = props.context;

    return (
        <ModalPortalProvider value={props.context}>
            <div className={classes.modalPortal} data-is-active={isActive}>
                {isActive && (
                    <Delay delay={1}>
                        <Suspense fallback={<ModalLoading closeModal={() => props.context.closeModal()} />}>
                            <LazyModalContents type={props.context.type} />
                        </Suspense>
                    </Delay>
                )}
            </div>
        </ModalPortalProvider>
    );
}

ModalPortal.defaultProps = defaultModalPortalProps;
