import React from 'react';

import { Modal } from 'src/components/Modal/Modal';
import { StaticSpinner } from 'src/components/Layout/Spinner';

import { ModalCloseProps } from '../ModalPortal.types';

import classes from './ModalLoading.scss';

export type ModalLoadingProps = ModalCloseProps;

/**
 * Modal shown whilst the requested modal
 * is loading.
 */
export function ModalLoading(props: ModalLoadingProps) {
    return (
        <Modal
            ariaLabel="Loading"
            contentClassName={classes.modalLoading}
            isOpen={true}
            onDismiss={() => {
                props.closeModal();
            }}
        >
            <StaticSpinner />
        </Modal>
    );
}
