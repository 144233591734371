import { useMutation } from '@apollo/react-hooks';
import { useSetResolved } from '@wearejh/m2-pwa-engine/lib/router/index';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

import { useDeps } from 'src/hooks/useDeps';
import introspectionQueryResultData from 'src/wordpress/types/fragmentTypes.json';

import GetJWT from '../queries/mutations/GenerateJWT.mutation.graphql';
import RefreshToken from '../queries/mutations/RefreshToken.mutation.graphql';

export const useGetJWT = () => {
    const { SSR_GQL, ORIGIN_UL, ORIGIN_UL_CLIENT } = useDeps().env;
    const isServer = typeof window === 'undefined';
    useSetResolved();

    const GetJWTClient = new ApolloClient({
        name: 'GetJWTClient',
        link: new HttpLink({
            uri: isServer ? ORIGIN_UL : ORIGIN_UL_CLIENT,
            useGETForQueries: false,
            headers: {
                'Content-Type': 'application/json',
            },
        }),
        cache: new InMemoryCache({
            fragmentMatcher: new IntrospectionFragmentMatcher({
                introspectionQueryResultData: introspectionQueryResultData,
            }),
        }),
    });

    const queryOptions = {
        client: GetJWTClient,
        ssr: SSR_GQL,
    };

    const [getTheJWT] = useMutation(GetJWT, queryOptions);
    const [refreshToken] = useMutation(RefreshToken, queryOptions);

    return {
        getToken: getTheJWT,
        refreshToken: refreshToken,
    };
};

export default useGetJWT;
