import React from 'react';
import { SearchAction, WebSite } from 'schema-dts';

import { usePathname } from 'src/hooks/router-hooks';
import { useDeps } from 'src/hooks/useDeps';
import { OrganizationStructuredData } from 'src/components/Schema/Organization';

import { Schema } from '../Schema/Schema';

export const BrandSchema = React.memo(function BrandSchema() {
    const { contact } = useDeps();
    const pathname = usePathname();

    return (
        <>
            <OrganizationStructuredData />
            <Schema
                input={
                    {
                        '@context': 'http://schema.org',
                        '@type': 'WebSite',
                        name: contact.name,
                        url: contact.publicUrl,
                        ...((() => {
                            if (pathname !== '/') {
                                return undefined;
                            }
                            return {
                                potentialAction: {
                                    '@type': 'SearchAction',
                                    target: `${contact.publicUrl}/catalogsearch/results?query={search_term_string}` as any,
                                    'query-input': 'required name=search_term_string',
                                } as SearchAction,
                            };
                        })() as any),
                    } as WebSite
                }
            />
        </>
    );
});
