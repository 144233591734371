import { ArchivePosts, ArchivePostsRaw } from '../types';

export const normalizeArchivePosts = (posts: ArchivePostsRaw[]): ArchivePosts[] => {
    return posts?.map((post) => {
        const imageSizes = post?.featuredImage?.node?.mediaDetails?.sizes?.sort((a, b) => {
            return Number(a.width) - Number(b.width);
        });
        return {
            postTitle: post?.title ?? undefined,
            postSlug: post?.slug ?? undefined,
            categories: post?.categories ?? undefined,
            featuredImage:
                {
                    fullURL: post?.featuredImage?.node?.fullURL ?? undefined,
                    sizes: imageSizes ?? undefined,
                    alt: post?.featuredImage?.node?.altText ?? undefined,
                } ?? undefined,
        };
    });
};

export default normalizeArchivePosts;
