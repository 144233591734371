import React from 'react';
import { ContactPoint, DayOfWeek, OpeningHoursSpecification } from 'schema-dts';

import { useDeps } from 'src/hooks/useDeps';

import { contact as Contact, socialLinks as SocialLinks } from '../../config.json';

import { Schema } from './Schema';

export function getOrganizationSchema(contact: typeof Contact, socialLinks: typeof SocialLinks) {
    const contactPoint: ContactPoint[] = contact.contactPoints.map((item) => {
        const hoursAvailable: OpeningHoursSpecification[] = item.openingHours.map((openingHour) => {
            const dayOfWeek = openingHour.dayOfWeek.map((day) => DayOfWeek[day]);

            return {
                '@type': 'OpeningHoursSpecification',
                closes: openingHour.closes,
                dayOfWeek,
                opens: openingHour.opens,
            };
        });

        return {
            '@type': 'ContactPoint',
            contactType: item.contactType,
            email: item.email,
            hoursAvailable,
        };
    });

    return {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        contactPoint,
        logo: contact.logo,
        name: contact.name,
        sameAs: [
            socialLinks.facebook,
            socialLinks.instagram,
            socialLinks.pinterest,
            socialLinks.twitter,
            socialLinks.youtube,
            socialLinks.tiktok,
        ],
        url: contact.publicUrl,
    };
}

export const OrganizationStructuredData = React.memo(function OrganizationStructuredData() {
    const { contact, socialLinks } = useDeps();

    const organizationSchema = getOrganizationSchema(contact, socialLinks);

    return <Schema input={organizationSchema} />;
});
