import React from 'react';
import { MessageType } from '@wearejh/m2-pwa-engine/lib/types';

import classes from './Messages.scss';

export type UiMessage = {
    type: MessageType;
    title?: React.ReactNode | string;
    text: React.ReactNode | string;
};

type Props = {
    messages: UiMessage[];
};

export function Messages(props: Props) {
    return (
        <div className={classes.root} data-test-id="Messages">
            {props.messages.map((x, i) => {
                const titleType = typeof x.title;
                const textType = typeof x.text;
                return (
                    <div key={i} className={classes.message} data-message-type={x.type}>
                        {x.title && titleType === 'string' && <p className={classes.title}>{x.title}</p>}
                        {x.title && titleType !== 'string' && x.title}
                        {x.text && textType === 'string' && (
                            <p className={classes.text}>{formatErrorMessage(x.text)}</p>
                        )}
                        {x.text && textType !== 'string' && x.text}
                    </div>
                );
            })}
        </div>
    );
}

function formatErrorMessage(input: React.ReactNode): string {
    if (typeof input !== 'string') return 'String not provided!';
    return input.replace('GraphQL error:', '');
}
