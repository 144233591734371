import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Wrapper } from 'src/components/Layout';

import { ButtonLink } from '../Button/ButtonLink';
import { paths } from '../../../../pwa/src/config.json';
import { Svg } from '../Svg/Svg';

import classes from './HeaderCheckout.scss';
import { Logo } from './Logo';

export function HeaderCheckout() {
    return (
        <Wrapper element="header" bg="grey" className={classes.header} data-test-id="CheckoutHeader">
            <Container className={classes.headerContainer}>
                <Link to="/" className={classes.headerLogoLink}>
                    <Logo variant="checkout" />
                </Link>
                <ButtonLink
                    to={paths.basket.basket}
                    variant="reset"
                    icon="left-of-text"
                    className={classes.backToCartButton}
                >
                    <Svg name="arrow-left" />
                    <span>Back to Cart</span>
                </ButtonLink>
            </Container>
        </Wrapper>
    );
}
