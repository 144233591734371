import { onError } from 'apollo-link-error';

export const suppressGqlErrors = onError(({ operation, graphQLErrors, response }) => {
    if (!graphQLErrors) {
        return;
    }
    if (response && Array.isArray(response.errors) && response.data && response.data[operation.operationName]) {
        response.errors = [];
        return;
    }
    if (operation.operationName === 'promoBanner' && response) {
        // eslint-disable-next-line no-console
        console.log('removing promoBanner errors');
        response.errors = [];
        return;
    }
    if (operation.operationName === 'productDetail' && response) {
        if (response.errors?.some((e) => e.path?.some((p) => p === 'size_guides'))) {
            // eslint-disable-next-line no-console
            console.log('removing size_guides errors');
            response.errors = [];
            return;
        }
    }
    if (
        (operation.operationName === 'productList' ||
            operation.operationName === 'productDetail' ||
            operation.operationName === 'bundleProductDetail') &&
        response
    ) {
        if (errorPathHas(['media_gallery'], response.errors)) {
            // eslint-disable-next-line no-console
            console.log('removing media_gallery errors from `%s`', operation.operationName);
            response.errors = [];
            return;
        }
    }
    if (operation.operationName === 'categoryProducts' && response) {
        if (errorPathHas(['price_range'], response.errors)) {
            // eslint-disable-next-line no-console
            console.log('removing price_range errors from `%s`', operation.operationName);
            response.errors = [];
            return;
        }
        if (response.errors?.some((e) => e.message.indexOf('"$productFilters"') > -1)) {
            // eslint-disable-next-line no-console
            console.log('removing $productFilters errors from `%s`', operation.operationName);
            response.errors = [];
            return;
        }
    }
    if (!response || !Array.isArray(response.errors)) {
        return;
    }
    const errorPaths = [
        ['swatch_data'],
        ['category', 'image'],
        ['categoryList', 'image'],
        ['cmsBlocks', 'items'],
        ['getCart', 'items'],
        ['homepageCarousel'],
    ];
    errorPaths.forEach((path) => {
        if (errorPathHas(path, response.errors)) {
            console.error('Error suppressed from operation: `%s`', operation.operationName, response.errors);
            response.errors = [];
        }
    });
});

function errorPathHas(search: (string | number)[], errors?: readonly any[]): boolean {
    return Boolean(errors?.some((e) => search.every((s) => e.path?.indexOf(s) !== -1)));
}
