export enum ModalPortalType {
    Default = 'SignIn',
    ForgotPassword = 'ForgotPassword',
    Register = 'Register',
    SignIn = 'SignIn',
}

export interface ModalCloseProps {
    closeModal(): void;
}

export interface ModalIsActiveProps {
    isActive: boolean;
}

export interface ModalSetModalTypeProps {
    setModalType(_: ModalPortalType): void;
}

export interface ModalTypeProps {
    type: ModalPortalType;
}

export interface ModalPortalContextProps
    extends ModalCloseProps,
        ModalIsActiveProps,
        ModalSetModalTypeProps,
        ModalTypeProps {}
