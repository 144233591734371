import { stripDomain } from 'src/util/sanitizeLink';

export function imagePath(input?: string | null): string {
    const placeholder = require('../../static/img/placeholder.jpg');

    if (!input) return placeholder;

    // If one of the below paths exists in the input string, return the placeholder
    const paths = [
        'placeholder/small_image.jpg',
        'placeholder/image.jpg',
        'catalog/product/placeholder',
        'Magento_Catalog',
    ];

    const hasPlaceholderPath = paths.some((path) => input.includes(path));

    if (hasPlaceholderPath) {
        return placeholder;
    }

    if (input.startsWith('https://thumbs.nosto.com')) {
        return input;
    }

    if (input.startsWith('https://placehold.it')) {
        return input;
    }

    return stripDomain(input);
}
