import React from 'react';

import { Cards } from 'src/components/Common/Cards';
import { useDeps } from 'src/hooks/useDeps';
import { FooterSocial } from 'src/components/Footer/FooterSocial';

import classes from './FooterLegal.scss';

type FooterLegalProps = {
    variant: 'normal' | 'checkout';
};

export const FooterLegal = React.memo(function FooterLegal(props: FooterLegalProps) {
    const { contact } = useDeps();
    return (
        <div className={classes.legal} data-variant={props.variant}>
            {props.variant === 'normal' && (
                <div className={classes.social}>
                    <FooterSocial />
                </div>
            )}
            <div className={classes.details}>
                <div className={classes.cards}>
                    <Cards activeTypes={[]} />
                </div>
                <p className={classes.copyright}>
                    © {new Date().getFullYear()} {contact.copyright}
                </p>
            </div>
        </div>
    );
});
