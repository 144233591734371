import { useDeps } from 'src/hooks/useDeps';

/**
 * This custom hook can be used to get a cleaned media url for a media item that is hosted on the Wordpress
 * instance at WP Engine. This is used in tandem with a proxy, to allow media to be picked up by Google Bots.
 * @param mediaUrl string
 */
export const useCleanMediaUrl = (mediaUrl?: string): { cleanMediaUrl: string } => {
    const { WP_BACKEND, DOMAIN } = useDeps().env;

    const stringsToFind = WP_BACKEND.replace('/blog/graphql', '');
    const cleanedMediaUrl =
        mediaUrl !== undefined ? 'https://' + mediaUrl.replace(stringsToFind, DOMAIN + '/community/media') : '';

    return {
        cleanMediaUrl: cleanedMediaUrl,
    };
};

export default useCleanMediaUrl;
