import { Location } from 'history';
import { BehaviorSubject, Observable } from 'rxjs';
import { useConstant } from '@wearejh/react-hooks/lib/useConstant';
import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useIsSignedIn } from '@wearejh/m2-pwa-user/lib/hooks/useIsSignedIn';
import { tap } from 'rxjs/operators';

import { useDeps } from 'src/hooks/useDeps';
import { StoreState } from 'src/types/global-types';

const searchSelector = (s: StoreState) => s.router.location.search;
const selector = (s: StoreState) => s.router.location.pathname;
const locationSelector = (s: StoreState) => s.router.location;

export function useSearch() {
    return useSelector(searchSelector, shallowEqual);
}

export function useRouterLocationCausesRender() {
    return useSelector(locationSelector, shallowEqual);
}

export function usePathname() {
    return useSelector(selector, shallowEqual);
}

export function usePathnameObservable(): Observable<string> {
    const p = usePathname();
    const c = useConstant(() => new BehaviorSubject(p));
    useEffect(() => {
        c.next(p);
    }, [c, p]);
    return c;
}

export function useHistoryLocationObservable() {
    const { historyEvents$, history } = useDeps();
    const historyLocation$ = useConstant(() => new BehaviorSubject<Location>(history.location));
    useEffect(() => {
        const sub = historyEvents$
            .pipe(
                tap((evt: any) => {
                    historyLocation$.next(evt.location);
                }),
            )
            .subscribe();
        return () => sub.unsubscribe();
    }, [historyEvents$, historyLocation$]);
    return historyLocation$;
}

export function useSignedInObservable(): Observable<boolean> {
    const p = useIsSignedIn();
    const c = useConstant(() => new BehaviorSubject(p));
    useEffect(() => {
        c.next(p);
    }, [c, p]);
    return c;
}
