import React from 'react';

import { NostoPlacements, NostoPageType } from 'src/components/Nosto';
import { BasketItemType } from 'src/components/Basket/BasketContext';

import { nosto } from '../../config.json';

import styles from './styles/Nosto.scss';
import type { NostoPlacementType } from './types';

export type NostoProps = {
    type: NostoPlacementType;
    productId?: number | string;
    categoryPath?: string;
    cartItems?: BasketItemType[];
    blogPostIds?: string[];
};

/**
 * Our core Nosto component. This is to be used wherever we want to render out Nosto placements. It is designed
 * to accept the data that we need it too, such as the placement type, product id, categories etc.
 * @param props
 * @constructor
 */
export const Nosto = (props: NostoProps) => {
    const placements = nosto.placements[props.type];
    return (
        <div id="nosto-placement-wrapper" className={styles.nostoPlacementWrapper}>
            <NostoPageType pageType={props.type} />
            <NostoPlacements
                type={props.type}
                productId={props.productId}
                placementIds={placements}
                categoryPath={props.categoryPath}
                blogPostIds={props?.blogPostIds}
            />
        </div>
    );
};
