import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

export type ButtonProps = {
    type?: 'button' | 'reset' | 'submit';
    variant?: 'primary' | 'secondary' | 'bold' | 'light' | 'alert' | 'reset' | 'reset-warn';
    icon?: 'right' | 'center' | 'left-of-text' | 'right-of-text';
    width?: 'auto' | 'max' | 'full';
    size?: 'normal' | 'small';
    shape?: 'normal' | 'circle';
    element?: 'button' | 'a';
    to?: string | { [index: string]: any };
    external?: boolean;
    onClick?(e: any): void;
    className?: string;
    [index: string]: any;
};

export function Button(props: ButtonProps) {
    const { children, to, element, type, variant, width, icon, size, shape, className, external, ...restProps } = props;

    const classes = classnames({
        ['button']: true,
        [`button--${variant}`]: true,
        [`button--reset`]: variant === 'reset',
        ['button--width-max']: width === 'max',
        ['button--width-full']: width === 'full',
        ['button--icon-right']: icon && icon === 'right',
        ['button--icon-center']: icon && icon === 'center',
        ['button--icon-left-of-text']: icon && icon === 'left-of-text',
        ['button--icon-right-of-text']: icon && icon === 'right-of-text',
        ['button--size-small']: size && size === 'small',
        ['button--shape-circle']: shape && shape === 'circle',
        [className || '']: true,
    });

    const inner = <span className="button__content">{children}</span>;

    if (element === 'button') {
        /* eslint-disable react/button-has-type */
        return (
            <button className={classes} type={type} {...restProps}>
                {inner}
            </button>
        );
        /* eslint-enable react/button-has-type */
    }

    if (element === 'a' && to) {
        if (external) {
            return (
                <a href={to as string} className={classes} {...restProps}>
                    {inner}
                </a>
            );
        }
        return (
            <Link to={to} className={classes} {...restProps}>
                {inner}
            </Link>
        );
    }

    return null;
}

Button.defaultProps = {
    type: 'button',
    variant: 'primary',
    width: 'auto',
    size: 'normal',
    shape: 'normal',
    element: 'button',
    external: false,
};
