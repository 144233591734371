import React from 'react';

import classes from 'src/components/Footer/FooterSocial.scss';
import { socialLinks } from 'src/config.json';
import { Svg } from 'src/components/Svg/Svg';

export function FooterSocial() {
    return (
        <ul className={classes.root}>
            <li className={classes.item}>
                <a
                    className={[classes.link, classes.tiktok].join(' ')}
                    href={socialLinks.tiktok}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Svg name="tiktok" />
                </a>
            </li>
            <li className={classes.item}>
                <a
                    className={[classes.link, classes.facebook].join(' ')}
                    href={socialLinks.facebook}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Svg name="facebook" />
                </a>
            </li>
            <li className={classes.item}>
                <a
                    className={[classes.link, classes.youtube].join(' ')}
                    href={socialLinks.youtube}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Svg name="youtube" />
                </a>
            </li>
            <li className={classes.item}>
                <a
                    className={[classes.link, classes.pinterest].join(' ')}
                    href={socialLinks.pinterest}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Svg name="pinterest" />
                </a>
            </li>
            <li className={classes.item}>
                <a
                    className={[classes.link, classes.instagram].join(' ')}
                    href={socialLinks.instagram}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Svg name="instagram" />
                </a>
            </li>
        </ul>
    );
}
