import type {
    SinglePostRaw,
    SinglePost,
    SinglePostFeaturedImageRaw,
    SinglePostCategoriesRaw,
    RelatedPostsRaw,
    RelatedPosts,
    SinglePostCategories,
    SinglePostFeaturedImage,
    SinglePostSEO,
} from '../types';
import { SinglePostSEORaw } from '../types';

export const normalizeSinglePost = (post: SinglePostRaw): SinglePost => {
    return {
        id: post?.databaseId ?? undefined,
        postTitle: post?.title ?? undefined,
        postBlocks: post?.blocks ?? undefined,
        postSlug: post?.slug ?? undefined,
        postModified: post?.modifiedGmt ?? undefined,
        postDate: post?.dateGmt ?? undefined,
        postCategories: normalizeSinglePostCategories(post?.categories) ?? undefined,
        featuredImage: normalizeSinglePostFeaturedImage(post?.featuredImage?.node) ?? undefined,
        acfPostMeta: {
            displayDateOnArticle: post?.acfPostMeta?.displayDateOnArticle ?? undefined,
            relatedPosts: normalizeRelatedPosts(post?.acfPostMeta?.relatedPosts) ?? undefined,
        },
        seo: normalizeSEOData(post?.seo) ?? undefined,
    };
};

export const normalizeSinglePostCategories = (
    categories?: SinglePostCategoriesRaw,
): SinglePostCategories[] | undefined => {
    return categories?.edges?.map((category) => ({
        isPrimary: category?.isPrimary ?? undefined,
        name: category?.node?.name ?? undefined,
        slug: category?.node?.slug ?? undefined,
    }));
};

export const normalizeSinglePostFeaturedImage = (image?: SinglePostFeaturedImageRaw): SinglePostFeaturedImage => {
    const imageSizes = image?.mediaDetails?.sizes?.sort((a, b) => {
        return Number(a.width) - Number(b.width);
    });

    return {
        fullURL: image?.fullURL ?? undefined,
        sizes: imageSizes ?? undefined,
        alt: image?.altText ?? undefined,
    };
};

export const normalizeSEOData = (seo?: SinglePostSEORaw): SinglePostSEO => {
    return {
        breadcrumbs: seo?.breadcrumbs ?? undefined,
        schema: seo?.schema?.raw ?? undefined,
        title: seo?.title ?? undefined,
        metaDesc: seo?.metaDesc ?? undefined,
        metaKeywords: seo?.metaKeywords ?? undefined,
        focusKeywords: seo?.focuskw ?? undefined,
        opengraph: {
            ogTitle: seo?.opengraphTitle ?? undefined,
            ogType: seo?.opengraphType ?? undefined,
            ogURL: seo?.opengraphUrl ?? undefined,
            ogDescription: seo?.opengraphDescription ?? undefined,
            ogSiteName: seo?.opengraphSiteName ?? undefined,
            ogArticlePublished: seo?.opengraphPublishedTime ?? undefined,
        },
    };
};

export const normalizeRelatedPosts = (relatedPosts: RelatedPostsRaw[] | undefined): RelatedPosts[] | undefined => {
    return relatedPosts?.map((relatedPost) => {
        const imageSizesRelated = relatedPost?.featuredImage?.node?.mediaDetails?.sizes?.sort((a, b) => {
            return Number(b.width) - Number(a.width);
        });
        return {
            postTitle: relatedPost.title ?? undefined,
            postSlug: relatedPost.slug ?? undefined,
            featuredImage:
                {
                    fullURL: relatedPost?.featuredImage?.node?.fullURL ?? undefined,
                    sizes: imageSizesRelated ?? undefined,
                    alt: relatedPost?.featuredImage?.node?.altText ?? undefined,
                } ?? undefined,
            categories: relatedPost?.categories ?? undefined,
        };
    });
};

export default normalizeSinglePost;
