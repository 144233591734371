import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

import introspectionQueryResultData from 'src/wordpress/types/fragmentTypes.json';
import { useDeps } from 'src/hooks/useDeps';

const useWordpressClient = () => {
    const { ORIGIN_UL, ORIGIN_UL_CLIENT } = useDeps().env;
    const isServer = typeof window === 'undefined';
    const token = isServer ?? localStorage.getItem('wp_token');

    const httpLink = new HttpLink({
        uri: isServer ? ORIGIN_UL : ORIGIN_UL_CLIENT,
        useGETForQueries: false,
        headers: {
            'Content-Type': 'application/json',
            Authorization: isServer ?? `Bearer ${token}`,
        },
    });

    const WordpressClient = new ApolloClient({
        name: 'WordpressClient',
        link: httpLink,
        cache: new InMemoryCache({
            fragmentMatcher: new IntrospectionFragmentMatcher({
                introspectionQueryResultData: introspectionQueryResultData,
            }),
        }),
    });

    return {
        WordpressClient,
    };
};

export default useWordpressClient;
