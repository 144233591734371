import React from 'react';
import { Link } from 'react-router-dom';

import { useDeps } from 'src/hooks/useDeps';

import { Svg } from '../Svg/Svg';

import classes from './FooterFeatures.scss';

export const FooterFeatures = React.memo(function FooterFeatures() {
    const { paths } = useDeps();
    return (
        <div className={classes.footerControls}>
            <Link
                to={paths.community.joinCommunity}
                className={[classes.footerFeature, classes.footerFeatureCommunity].join(' ')}
            >
                <Svg name="community-icon" />
                <span className={classes.footerFeatureContent}>
                    Join our <br className="hidden-desktop" /> Community
                </span>
            </Link>
            <Link
                to={paths.storeLocator.storeLocator}
                className={[classes.footerFeature, classes.footerFeatureContact].join(' ')}
            >
                <Svg name="place" />
                <span className={classes.footerFeatureContent}>Stores</span>
            </Link>
            <Link to="/click-collect" className={[classes.footerFeature, classes.footerFeatureCollect].join(' ')}>
                <Svg name="click-collect" />
                <span className={classes.footerFeatureContent}>Click & Collect</span>
            </Link>
            <Link
                to="/delivery-information"
                className={[classes.footerFeature, classes.footerFeatureDelivery].join(' ')}
            >
                <Svg name="delivery" />
                <span className={classes.footerFeatureContent}>Delivery</span>
            </Link>
            <a
                href="https://help.woodies.ie/knowledge"
                className={[classes.footerFeature, classes.footerFeatureFaqs].join(' ')}
                target="_blank"
                rel="noreferrer"
            >
                <Svg name="faq" />
                <span className={classes.footerFeatureContent}>FAQs</span>
            </a>
        </div>
    );
});
