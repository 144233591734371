import { useCallback } from 'react';
import zenscroll from 'zenscroll';

/**
 * Smooth scroll to an element by selector only
 */
export function useSmoothScrollToSelector() {
    return useCallback((selector: string) => {
        const elem = document.querySelector(selector);
        if (elem) {
            zenscroll.to(elem);
        } else {
            console.error('element not found with selector', selector);
        }
    }, []);
}
