import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';

import classes from './TwoCol.scss';

type TwoColProps = {
    divide?: boolean;
    left?: React.ReactNode;
    main: React.ReactNode;
    right?: React.ReactNode;
    variant?: string;
} & HTMLAttributes<any>;

export const TwoCol: React.FC<TwoColProps> = React.memo((props) => {
    const {
        left,
        right,
        main,
        variant,
        ...rest
    } = props;

    return (
        <section className={classes.root} data-layout-variant={variant} {...rest}>
            {props.left && (
                <div className={classes.left}>{props.left}</div>
            )}

            <div
                className={classnames({
                    [classes.main]: true,
                    [classes.hasLeft]: props.left,
                    [classes.hasRight]: props.right,
                })}
            >
                {props.main}
            </div>
            {props.right && (
                <div
                    className={classnames({
                        [classes.right]: true,
                        [classes.divide]: props.divide,
                    })}
                >
                    {props.right}
                </div>
            )}
        </section>
    );
});
