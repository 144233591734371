import { useEffect, useRef } from 'react';

import { createDebug } from '../../../util/debug';
import { GtmEvents } from '../utils/eventTypes';
import { hasGtm } from '../gtm.types';

const debug = createDebug('useGtm');

/**
 * Fire a Google Tag Manager Event.
 *
 * This function will check if Google Tag Manager
 * is active on the page before firing.
 *
 * Note that React components should utilize the
 * `useGTM()` hook.
 *
 * @param event Google Tag Manager Event
 */
export function fireGTMEvent(event: GtmEvents) {
    const gtmIsEnabled = hasGtm(window);

    if (gtmIsEnabled) {
        debug('pushing to dataLayer', event);

        (window as any).dataLayer.push(event);

        debug('next dataLayer', (window as any).dataLayer);
    } else {
        debug('no window.dataLayer, doing nothing with', event);
    }
}

/**
 * Option types used by `useGTM` hook.
 */
interface UseGTMOptions {
    /**
     * Should the event only be triggered once?
     *
     * Set to `true` to make the event only fire once.
     *
     * Set to `false` if you always want the event
     * to be fired.
     *
     * @example
     * // Always fire event.
     * ...
     *
     * const { loading } = props;
     *
     * useGtm({
     *   event: 'eventName',
     * }, {
     *  // Google Tag Manager event will be triggered
     *  // on every change to `loading`.
     *  onceOnly: false,
     * });
     *
     * ...
     */
    onceOnly?: boolean;

    /**
     * Should the event be fired?
     *
     * Use this to conditionally trigger an event to fire.
     *
     * @example
     * // Conditional firing of event based on a variable.
     * ...
     *
     * const { loading } = props;
     *
     * useGtm({
     *   event: 'eventName',
     * }, {
     *  // Google Tag Manager event will not be
     *  // fired until loading is `false`
     *  shouldFire: !loading,
     * });
     *
     * ...
     */
    shouldFire?: boolean;
}

/**
 * Default property values used by `useGTM`
 */
const defaultUseGTMOptions: Pick<UseGTMOptions, 'onceOnly' | 'shouldFire'> = {
    onceOnly: true,
    shouldFire: true,
};

/**
 * React hook for firing Google Tag Manager events.
 *
 * @param event - Google Tag Manager event
 * @param options - Options to configure hook
 *
 * @example
 * // Simple event that only fires once.
 *
 * function Component() {
 *   useGtm({
 *     event: 'eventName',
 *   });
 *
 *   return (
 *     <>...</>
 *   )
 * }
 *
 * @example
 * // Always fire event.
 * ...
 *
 * const { loading } = props;
 *
 * useGtm({
 *   event: 'eventName',
 * }, {
 *  // Google Tag Manager event will be triggered
 *  // on every change to `loading`.
 *  onceOnly: false,
 * });
 *
 * ...
 *
 * @example
 * // Conditional single firing of event based on a variable.
 * ...
 *
 * const { loading } = props;
 *
 * useGtm({
 *   event: 'eventName',
 * }, {
 *  // Google Tag Manager event will not be
 *  // fired until loading is `false`
 *  shouldFire: !loading,
 * });
 *
 * ...
 */
export function useGTM(event: GtmEvents, options: UseGTMOptions = defaultUseGTMOptions) {
    const { onceOnly, shouldFire } = Object.assign(defaultUseGTMOptions, options);

    const hasTriggered = useRef(false);

    useEffect(() => {
        if (shouldFire) {
            if ((onceOnly && !hasTriggered.current) || !onceOnly) {
                hasTriggered.current = true;
                fireGTMEvent(event);
            }
        }
    }, [event, onceOnly, shouldFire]);
}
