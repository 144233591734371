import { createContext } from 'react';

import { ModalPortalContextProps, ModalPortalType } from './ModalPortal.types';

export const defaultModalPortalContextProps: ModalPortalContextProps = {
    closeModal: () => {},
    isActive: false,
    setModalType: (_: ModalPortalType) => {},
    type: ModalPortalType.SignIn,
};

/**
 * Context used by `ModalPortal` & children.
 */
export const ModalPortalContext = createContext<ModalPortalContextProps>(defaultModalPortalContextProps);

/**
 * Provide context for `ModalPortal` & children.
 */
export const ModalPortalProvider = ModalPortalContext.Provider;
