import React from 'react';
import { ApolloProvider, useQuery } from '@apollo/react-hooks';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import postsQuery from 'src/wordpress/queries/GetPostsByArrayOfIDs.graphql';
import { useDeps } from 'src/hooks/useDeps';
import { LoaderBar } from 'src/components/Helpers/Skeleton';
import { ErrorComponent } from 'src/components/Error/ErrorComponent';
import { normalizePostsArray } from 'src/wordpress/utils';
import { PostListGrid } from 'src/wordpress/components/PostListGrid';
import useWordpressClient from 'src/clients/useWordpressClient';
import type { PostData } from 'src/wordpress/types';

import styles from './PostsByArrayOfIDs.scss';

type PostsByArrayOfIDsProps = {
    postIds?: string[];
};

type PostsByArrayOfIDsInnerProps = PostsByArrayOfIDsProps;

export const PostsByArrayOfIDsInner = (props: PostsByArrayOfIDsInnerProps) => {
    const { postIds } = props;

    const { loading, error, data } = useQuery(postsQuery, {
        variables: { idArray: postIds },
        ssr: useDeps().env.SSR_GQL,
        skip: postIds && postIds.length === 0, // skip if no blog post ids are present
    });

    if (loading) {
        return <LoaderBar />;
    }

    if (error) {
        return <ErrorComponent message={error?.message} />;
    }

    let posts: PostData[] | undefined = undefined;

    if (data) {
        posts = normalizePostsArray(data.blogPosts.nodes);
    }

    if (postIds && posts && posts.length > 0) {
        return (
            <div className={classnames('communityFeed', styles['communityFeed'])}>
                <Link to="/community/" className={styles['blockTitleLink']}>
                    <h2>Ideas and Advice</h2>
                </Link>
                <PostListGrid posts={posts} variant="plp-pdp" />
            </div>
        );
    }

    return null;
};

export const PostsByArrayOfIDs = (props: PostsByArrayOfIDsProps) => {
    const { WordpressClient } = useWordpressClient();
    return (
        <ApolloProvider client={WordpressClient}>
            <PostsByArrayOfIDsInner {...props} />
        </ApolloProvider>
    );
};

export default PostsByArrayOfIDs;
