import React from 'react';

import { SkeletonBlock } from 'src/components/Helpers/SkeletonBlock';

import classes from './Skeleton.scss';

type Props = Record<string, unknown>;

export const Skeleton: React.FC<Props> = () => {
    return <div className={classes.empty} />;
};

export function LoaderBar() {
    return (
        <div className={classes.loaderBar}>
            <SkeletonBlock style={{ height: '10px', marginBottom: '20px' }} />
        </div>
    );
}

export function LoaderMinHeight() {
    return (
        <div className={[classes.loaderBar, classes.minHeight].join(' ')}>
            <SkeletonBlock style={{ height: '10px', marginBottom: '20px' }} className={classes.loaderBarInner} />
        </div>
    );
}
