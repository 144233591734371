import { RouteData } from '@wearejh/m2-pwa-engine/lib/utils/getKnownRoute';

import { paths } from './config.json';

function beginsWith(url: string, segment: string) {
    if (url === segment) {
        return true;
    }
    if (url.split('/')[1] === segment.slice(1)) {
        return true;
    }
    return false;
}

export const knownRoutes: RouteData[] = [
    {
        test: (url) => {
            return url === '/';
        },
        value: { type: 'HOME', id: 2, __typename: 'EntityUrl' },
    },
    {
        test: '/adyen',
        value: { type: 'CHECKOUT', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/checkout',
        value: { type: 'CHECKOUT', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/calculators',
        value: { type: 'CALCULATORS', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/basket',
        value: { type: 'BASKET', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: (url) => beginsWith(url, '/customer'),
        value: { type: 'CUSTOMER', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: paths.faq.faq,
        value: { type: 'FAQ_PAGE', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/offline',
        value: { type: 'OFFLINE', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/outdated',
        value: { type: 'OUTDATED', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/_reset',
        value: { type: 'RESET', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/catalogsearch',
        value: { type: 'CATALOG_SEARCH', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: paths.storeLocator.storeLocator,
        value: { type: 'STORE_LOCATOR', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/__test_config',
        value: { type: 'CONFIGURABLE_PRODUCT', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/__test_category_landing',
        value: { type: 'CATEGORY_LANDING_PAGE', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/__test_cms',
        value: { type: 'CMS_PAGE', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/community',
        value: { type: 'COMMUNITY', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/community/:slug',
        value: { type: 'COMMUNITY', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/klarna',
        value: { type: 'KLARNA', id: 0, __typename: 'EntityUrl' },
    },
];
