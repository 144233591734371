import React, { useContext, useEffect, useState, Fragment } from 'react';

import { NostoContext } from 'src/components/Nosto/NostoProvider';
import { NostoSinglePlacement, NostoPlacementWrapper } from 'src/components/Nosto';
import { DEFAULT_CURRENCY } from 'src/components/AppShell/app-shell.types';
import { PostsByArrayOfIDs } from 'src/wordpress/components/PostsByArrayOfIDs';

import { NostoRecommendations, NostoPlacementsProps, NostoRecommendationsRaw } from './types';
import { normalizeNostoRecommendations } from './utilities';

export const NostoPlacements = (props: NostoPlacementsProps) => {
    /**
     * Nosto Placement state
     * Default: empty array
     */
    const [nostoRecommendations, setNostoRecommendations] = useState<NostoRecommendations | null>(null);

    /**
     * Determine whether the Nosto Script is loaded or not
     */
    const { isLoaded: isNostoLoaded } = useContext(NostoContext);

    /**
     * Where we call our API. We run in a useEffect so that it only loads and fires once we have determined the
     * Nosto script has loaded correctly within the window.
     */
    useEffect(() => {
        /**
         * Has Nosto loaded? We only want to run api requests if it has.
         * This gets set in a Context Provider
         */
        if (isNostoLoaded) {
            /**
             * Fire the Nosto API on a callback function. We run a switch statement
             * to loop over each page type. This gets passed in when we call the <Nosto /> component
             */
            (window as any).nostojs((api) => {
                switch (props.type) {
                    // Homepage
                    case 'front': {
                        api.defaultSession()
                            .viewFrontPage()
                            .setPlacements(api.placements.getPlacements())
                            .load()
                            .then((response: NostoRecommendationsRaw) => {
                                // Once we know the API has done its thing, lets pass the data into a normalize function
                                const recommendationsArray = normalizeNostoRecommendations(response);
                                // Set the product recommendations data
                                setNostoRecommendations(recommendationsArray);
                                // Let's use the Nosto function for outputting the content campaigns
                                api.placements.injectCampaigns(response.campaigns.content);
                            });
                        break;
                    }
                    // Product pages
                    case 'product': {
                        api.defaultSession()
                            .viewProduct(props.productId) // Set the product we are viewing. ProductID is passed in from the Nosto Component
                            .setPlacements(api.placements.getPlacements())
                            .load()
                            .then((response: NostoRecommendationsRaw) => {
                                // Once we know the API has done its thing, lets pass the data into a normalize function
                                const recommendationsArray = normalizeNostoRecommendations(response);
                                // Set the product recommendations data
                                setNostoRecommendations(recommendationsArray);
                                // Let's use the Nosto function for outputting the content campaigns
                                api.placements.injectCampaigns(response.campaigns.content);
                            });
                        break;
                    }
                    // Category Pages
                    case 'category': {
                        api.defaultSession()
                            .viewCategory(props.categoryPath)
                            .setPlacements(api.placements.getPlacements())
                            .load()
                            .then((response: NostoRecommendationsRaw) => {
                                // Once we know the API has done its thing, lets pass the data into a normalize function
                                const recommendationsArray = normalizeNostoRecommendations(response);
                                // Set the product recommendations data
                                setNostoRecommendations(recommendationsArray);
                                // Let's use the Nosto function for outputting the content campaigns
                                api.placements.injectCampaigns(response.campaigns.content);
                            });
                        break;
                    }
                    // Search
                    case 'search': {
                        api.defaultSession()
                            .viewSearch('')
                            .setPlacements(api.placements.getPlacements())
                            .load()
                            .then((response: NostoRecommendationsRaw) => {
                                // Once we know the API has done its thing, lets pass the data into a normalize function
                                const recommendationsArray = normalizeNostoRecommendations(response);
                                // Set the product recommendations data
                                setNostoRecommendations(recommendationsArray);
                                // Let's use the Nosto function for outputting the content campaigns
                                api.placements.injectCampaigns(response.campaigns.content);
                            });
                        break;
                    }
                    // Error404
                    case 'notfound': {
                        api.defaultSession()
                            .viewNotFound()
                            .setPlacements(api.placements.getPlacements())
                            .load()
                            .then((response: NostoRecommendationsRaw) => {
                                // Once we know the API has done its thing, lets pass the data into a normalize function
                                const recommendationsArray = normalizeNostoRecommendations(response);
                                // Set the product recommendations data
                                setNostoRecommendations(recommendationsArray);
                                // Let's use the Nosto function for outputting the content campaigns
                                api.placements.injectCampaigns(response.campaigns.content);
                            });
                        break;
                    }
                    // Cart
                    case 'cart': {
                        /**
                         * Convert cart items into a Nosto friendly format
                         */
                        const NostoItems = props.cartItems?.map((item) => {
                            return {
                                name: item.cartItem.product.name ?? '',
                                product_id: item.cartItem.product.id.toString() ?? 0,
                                sku_id: item.cartItem.product.sku.toString(),
                                price_currency_code: DEFAULT_CURRENCY,
                                quantity: item.cartItem.quantity,
                                unit_price: item.cartItem.prices.price,
                            };
                        });

                        api.defaultSession()
                            .setCart({ items: NostoItems })
                            .viewCart()
                            .setPlacements(api.placements.getPlacements())
                            .load()
                            .update()
                            .then((response: NostoRecommendationsRaw) => {
                                // Once we know the API has done its thing, lets pass the data into a normalize function
                                const recommendationsArray = normalizeNostoRecommendations(response);
                                // Set the product recommendations data
                                setNostoRecommendations(recommendationsArray);
                                // Let's use the Nosto function for outputting the content campaigns
                                api.placements.injectCampaigns(response.campaigns.content);
                            });
                        break;
                    }
                    // Every other page
                    case 'other': {
                        api.defaultSession()
                            .viewOther()
                            .setPlacements(api.placements.getPlacements())
                            .load()
                            .then((response) => {
                                // Once we know the API has done its thing, lets pass the data into a normalize function
                                const recommendationsArray = normalizeNostoRecommendations(response);
                                // Set the product recommendations data
                                setNostoRecommendations(recommendationsArray);
                            });
                        break;
                    }
                } // End switch statement
            });
        }
    }, [isNostoLoaded, props.productId, props.type, props.categoryPath, props.cartItems]);

    /**
     * If we have recommendations data we want to render each set as a row
     */
    if (nostoRecommendations?.recommendations && nostoRecommendations?.recommendations?.length > 0) {
        return (
            <NostoPlacementWrapper>
                {nostoRecommendations?.recommendations?.map((recommendation, index) => {
                    return (
                        <Fragment key={`${recommendation.placement_id}--${recommendation.result_id}`}>
                            <NostoSinglePlacement
                                placementTitle={recommendation.title}
                                placementProducts={recommendation.products}
                                placementId={recommendation.placement_id}
                                placementType={props.type}
                                slotRef={recommendation.result_id}
                            />

                            {/* Make the blog post list appear between the first and subsequent nosto blocks */}
                            {index === 0 && props.type === 'product' && props?.blogPostIds && (
                                <PostsByArrayOfIDs postIds={props?.blogPostIds} />
                            )}
                        </Fragment>
                    );
                })}
            </NostoPlacementWrapper>
        );
    }

    // If we don't have recommendations, output nothing.
    return (
        <>
            {/* If we don't have any IDs, we may still want to show blog posts */}
            {!props?.placementIds && props.type === 'product' && props?.blogPostIds && (
                <PostsByArrayOfIDs postIds={props?.blogPostIds} />
            )}

            {props.placementIds.map((placement, index) => {
                return (
                    <Fragment key={`${placement}--${index}`}>
                        <div style={{ display: 'none' }} className="nosto_element" id={placement} />
                        {/* If we do have some IDs, but nothing renders, still output the blog Posts in the right place */}
                        {index === 0 && props.type === 'product' && props?.blogPostIds && (
                            <PostsByArrayOfIDs postIds={props?.blogPostIds} />
                        )}
                    </Fragment>
                );
            })}
        </>
    );
};

export default NostoPlacements;
