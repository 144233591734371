import React, { HtmlHTMLAttributes, useContext } from 'react';

import { ConfigContext } from 'src/components/AppShell/ConfigProvider';

import classes from './SimplePrice.scss';

type SimplePriceProps = {
    price: string | number;
    priceIncl: string | number;
    vat?: boolean;
    before?: string;
} & HtmlHTMLAttributes<any>;

export function Price(props: SimplePriceProps) {
    if (props.priceIncl === undefined || props.priceIncl === null) {
        console.error('missing `props.priceIncl` from Price component');
    }

    const { price, priceIncl, vat = false, ...rest } = props;
    const { amount, prefix, symbol } = usePrice(price, priceIncl ?? price, vat);

    return (
        <span {...rest}>
            {props.before && props.before}
            {prefix}
            {symbol}
            {amount}
        </span>
    );
}

type DisplayPrice = {
    prefix: string;
    symbol: string;
    amount: string;
};

export function usePrice(price: number | string, priceIncl: number | string = price, vat: boolean): DisplayPrice {
    const { currentCurrency } = useContext(ConfigContext);
    const subjectPrice = vat ? priceIncl : price;
    const symbol = currentCurrency.symbol;
    const float = parseFloat(String(subjectPrice));
    const prefix = float < 0 ? '-' : '';
    const amount = Math.abs(float).toLocaleString('en-EN', {
        minimumFractionDigits: 2,
    });
    return { symbol, prefix, amount };
}

export function usePriceString(price: number | string, vat: boolean): string {
    const { symbol, prefix, amount } = usePrice(price, price, vat);
    return `${prefix}${symbol}${amount}`;
}

export function StyledPrice(props: SimplePriceProps) {
    return <Price className={classes.root} price={props.price} vat={props.vat} priceIncl={props.priceIncl} />;
}

export const StyledText: React.FC = (props) => {
    return <span className={classes.root}>{props.children}</span>;
};
