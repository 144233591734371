import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';
import { shallowEqual, useSelector } from 'react-redux';

import { StoreState } from '../../types/global-types';
import { currentUrl } from '../../util/currentUrl';
import { useDeps } from '../../hooks/useDeps';
import meta from '../../pages/Home/home.json';

type HeadProps = Record<string, unknown>;

const selector = (s: StoreState) => s.router.location.pathname;

export const Head: React.FC<HeadProps> = () => {
    const pathname = useSelector(selector, shallowEqual);
    const {
        env: { DOMAIN },
        contact,
    } = useDeps();
    const url = currentUrl(DOMAIN!, pathname);
    return (
        <Helmet titleTemplate={`%s | ${contact.name}`}>
            <link rel="canonical" href={url} />
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover" />
            <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
            <link rel="apple-touch-icon" sizes="180x180" href="/static/icons/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/static/icons/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/static/icons/favicon-16x16.png" />
            <link rel="manifest" href="/static/icons/site.webmanifest" />
            <link rel="shortcut icon" href="/static/icons/favicon.ico" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="msapplication-config" content="/static/icons/browserconfig.xml" />
            <meta name="theme-color" content="#ffffff" />
            <meta name="keywords" content={meta.helmet.description} />
            <meta property="og:locale" content="en_GB" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url} />
            <meta property="og:site_name" content={contact.name} />
            <meta property="og:image" content="/static/icons/android-chrome-512x512.png" />
            <meta name="google-site-verification" content="o8IjS-yz2IsDcmI-lxfrC_2g-1bzUW8q7x9L5VehhlI" />
            <meta name="google-site-verification" content="fKLnD1szAhUb8_L_GqvEnBcj6DG2YI51i_vx0D2nbw0" />
        </Helmet>
    );
};

interface Meta {
    data: {
        title: string;
        description: string;
    };
}

export function DefaultMeta(props: PropsWithChildren<Meta>) {
    const { contact } = useDeps();
    return (
        <Helmet titleTemplate={`%s | ${contact.name}`}>
            <title>{props.data.title}</title>
            <meta name="title" content={props.data.title} />
            <meta name="description" content={props.data.description} />
            <meta property="og:title" content={props.data.title} />
            <meta property="og:description" content={props.data.description} />
        </Helmet>
    );
}
