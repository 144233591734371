import React from 'react';

import { ProductPrice } from 'src/types/global-types';
import { Price, usePrice } from 'src/components/Helpers/Price';

import classes from './ProductMainPrice.scss';

type Props = {
    price: ProductPrice;
    vat?: boolean;
    layout?: 'normal' | 'grid' | 'basket-item';
};

export function ProductMainPrice(props: Props) {
    const { symbol } = usePrice(props.price.price, props.price.price_incl ?? props.price.price, true);

    return (
        <div className={classes.root} data-layout={props.layout}>
            <div className={[classes.price, classes.priceTitle].join(' ')}>
                <Price
                    price={props.price.price}
                    priceIncl={props.price.price_incl}
                    vat={props.vat}
                    className={[classes.productDetailPrice].join(' ')}
                />
                {props.price.price_per && <span className={[classes.pricePer].join(' ')}>{props.price.price_per}</span>}
            </div>
            {props.price.regular_price && (
                <Price
                    before="Was "
                    price={props.price.regular_price}
                    priceIncl={props.price.regular_price}
                    vat={props.vat}
                    className={[classes.price, classes.productDetailPriceWas].join(' ')}
                />
            )}
            {props.price.discount && typeof props.price.discount === 'string' && (
                <span className={[classes.price, classes.productDetailPriceSaving].join(' ')}>
                    Save {props.price.discount}
                </span>
            )}
            {props.price.discount && typeof props.price.discount === 'number' && (
                <span className={[classes.price, classes.productDetailPriceSaving].join(' ')}>
                    Save {symbol}
                    {props.price.discount.toFixed(2)}
                </span>
            )}
        </div>
    );
}

ProductMainPrice.defaultProps = { vat: true, layout: 'normal' };
