import { useState, useEffect } from 'react';

export const useNostoScript = (nostoId: string) => {
    const [nostoScriptStatus, setNostoScriptStatus] = useState({ isLoaded: false });

    useEffect(() => {
        // Create the Nosto script element
        const script = document.createElement('script');

        // Setup attributes
        script.src = `https://connect.nosto.com/include/${nostoId}`;
        script.async = true;
        script.type = 'text/javascript';
        script.setAttribute('nosto-client-script', '');
        script.onload = () => {
            // Define it in our state if loaded
            setNostoScriptStatus({
                isLoaded: true,
            });
        };

        // Append to the document
        document.head.appendChild(script);

        // Remove the script
        return () => {
            document.head.removeChild(script);
        };
    }, [nostoId]);

    // Remove everything
    return nostoScriptStatus;
};

export default useNostoScript;
