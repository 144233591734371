import { ActionMap, createMsg } from 'action-typed';
import { Message } from '@wearejh/m2-pwa-engine/lib/types';

import { ModalPortalType } from 'src/components/ModalPortal/ModalPortal.types';

export type Messages = {
    ['App.OpenCart']: undefined;
    ['App.OpenCheckout']: undefined;
    ['App.OpenNav']: boolean;
    ['App.ToggleSearch']: boolean;
    ['App.OpenOverlay']: undefined;
    ['App.CloseDrawer']: undefined;
    ['App.AddMessages']: Message[];
    ['App.ClearMessages']: undefined;
    ['App.Log']: unknown;
    ['App.Noop']: undefined;
    ['App.OpenSubNav']: number[];
    ['App.CloseSubNav']: number;
    ['App.CloseAllNavs']: undefined;
    ['App.EnableNav']: undefined;
    ['App.DismissAlert']: undefined;
    ['App.ClearAlert']: undefined;
    ['App.ExternalLink']: { url: string; newTab: boolean };
    ['App.Reset']: undefined;
    ['App.Modal.Close']: undefined;
    ['App.Modal.Open.ForgotPassword']: undefined;
    ['App.Modal.Open.Register']: undefined;
    ['App.Modal.Open.SignIn']: undefined;
    ['App.Modal.Open']: undefined;
    ['App.Modal.OpenType']: ModalPortalType;
    ['App.Modal.SetType']: ModalPortalType;
};

export const Msg = createMsg<Messages>();
export const AppMsg = Msg;
export type TypeMap = ActionMap<Messages>;
export type Actions = TypeMap[keyof TypeMap];
export type AppActions = Actions;
