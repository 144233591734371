import React, { ImgHTMLAttributes } from 'react';

import { LazyImg } from '../../hooks/useLazyLoad';

import classes from './Spinner.scss';

type SpinnerProps = {
    showText?: boolean;
    loadingText?: string;
};

export const SPINNER = require('../../../static/img/spinner.gif');

export function Spinner(props: SpinnerProps) {
    return (
        <div className={classes.spinner}>
            <LazyImg src={SPINNER} alt="Please Wait..." />
            {props.showText && (
                <span className={classes.label}>{props.loadingText ? props.loadingText : 'Please wait...'}</span>
            )}
        </div>
    );
}

export function StaticSpinner(props: ImgHTMLAttributes<any>) {
    return (
        <img
            src={SPINNER}
            alt="Please Wait..."
            style={{ width: '20px', height: '20px', display: 'block' }}
            {...props}
        />
    );
}

Spinner.defaultProps = {
    showText: true,
};
