import React from 'react';

import { StaticSpinner } from 'src/components/Layout/Spinner';

import classes from './MiniCart.scss';
import { CloseMiniCart } from './MiniCart.types';

export const LazyConnectedMiniCartContents = React.lazy(() => import('./MiniCartContents'));

interface MiniCartProps extends CloseMiniCart {
    /**
     * Is the mini cart open?
     */
    isCartOpen: boolean;
}

const miniCartDefaultProps: Pick<MiniCartProps, 'isCartOpen'> = {
    isCartOpen: false,
};

export function MiniCart(props: MiniCartProps) {
    const { isCartOpen } = props;

    return (
        <div className={classes.minicartLoader} data-open={isCartOpen}>
            <div className={classes.root}>
                <span className={classes.triangle} />
                {isCartOpen && (
                    <React.Suspense fallback={<StaticSpinner className={classes.spinner} />}>
                        <LazyConnectedMiniCartContents closeMiniCart={props.closeMiniCart} />
                    </React.Suspense>
                )}
            </div>
        </div>
    );
}

MiniCart.defaultProps = miniCartDefaultProps;
