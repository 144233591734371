import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { HeaderVariant } from 'src/components/Header/HeaderVariant';
import { BrandSchema } from 'src/components/AppShell/BrandStructuredData';
import { ConnectedModalPortal } from 'src/components/ModalPortal/ConnectedModalPortal';
import { FooterWrap } from 'src/components/Footer/Footer';

import { Main } from '../Main/Main';
import { StoreState } from '../../types/global-types';
import { GTMScript } from '../Scripts/Scripts';

import { Head } from './Head';
import { ConfigProvider } from './ConfigProvider';
import { OfflineRibon } from './OfflineRibon';
import { GlobalBanner } from './GlobalBanner';

export const AppShell: React.FC = React.memo((props) => {
    return (
        <ConfigProvider>
            <BrandSchema />
            <Head />
            <GTMScript />
            <GlobalBanner />
            <HeaderVariant />
            <Main>
                <OfflineOutdated />
                {props.children}
            </Main>
            <div style={{ minHeight: '130px' }}>
                <FooterWrap />
            </div>
            <ConnectedModalPortal />
        </ConfigProvider>
    );
});

function stateSelector(state: StoreState) {
    return {
        outdated: state.runtime.outdated,
        online: state.runtime.online,
    };
}
function OfflineOutdated() {
    const state = useSelector(stateSelector, shallowEqual);

    useEffect(() => {
        if (state.outdated) {
            window.location.reload();
        }
    }, [state.outdated]);

    return !state.online ? <OfflineRibon /> : null;
}
