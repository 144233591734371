import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import './Container.scss';

type ContainerProps = {
    className?: string;
    variant?: 'normal' | 'narrow' | 'modal-narrow' | 'checkout-narrow';
    width?: 'page';
};

export function Container(props: PropsWithChildren<ContainerProps>) {
    return (
        <div
            className={classnames({
                ['container']: true,
                ['containerNarrow']: props.variant === 'narrow',
                ['containerModalNarrow']: props.variant === 'modal-narrow',
                ['containerCheckoutNarrow']: props.variant === 'checkout-narrow',
                [props.className || '']: true,
            })}
        >
            {props.children}
        </div>
    );
}

Container.defaultProps = {
    variant: 'normal',
    width: 'page',
};
