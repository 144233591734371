import React, { useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { useDeps } from 'src/hooks/useDeps';

import { Breakpoint, useBreakpoint } from '../../hooks/useBreakpoint';
import { Svg } from '../Svg/Svg';
import { stripDomain } from '../../util/sanitizeLink';

import classes from './FooterMenus.scss';

export interface FooterMenu {
    title: string;
    children: {
        title: string;
        url: string;
    }[];
}

type FooterMenusProps = {
    menus: FooterMenu[];
};

export const FooterMenus: React.FC<FooterMenusProps> = React.memo(({ menus }) => {
    const { DOMAIN } = useDeps().env;
    const [breakpoint] = useBreakpoint();
    const [openIndexes, toggleList] = useState([] as number[]);

    return (
        <div className={classes.root}>
            {menus.map((menu, index) => {
                const active = openIndexes.indexOf(index) > -1;
                return (
                    <div
                        key={menu.title}
                        className={classnames({
                            [classes.menu]: true,
                            [classes.menuTwoCol]: index === 0 || index === 1,
                        })}
                    >
                        <h3
                            className={classnames({
                                [classes.title]: true,
                                [classes.titleActive]: active,
                            })}
                            onClick={() => {
                                if (breakpoint === Breakpoint.Mob) {
                                    toggleList((children) => {
                                        if (children.indexOf(index) > -1) {
                                            return children.filter((x) => x !== index);
                                        }
                                        return children.concat(index);
                                    });
                                }
                            }}
                        >
                            {menu.title} <Svg name="arrow-down" />
                        </h3>
                        <ul
                            className={classnames({
                                [classes.list]: true,
                                [classes.listTwoCol]: index === 0 || index === 1,
                                [classes.listActive]: active,
                            })}
                        >
                            {menu.children.map((subchildren) => (
                                <li key={subchildren.title} className={classes.item}>
                                    <FooterMenuLink url={subchildren.url} title={subchildren.title} domain={DOMAIN} />
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            })}
        </div>
    );
});

type FooterMenuLinkProps = {
    url: string;
    title: string;
    domain: string;
};

function FooterMenuLink(props: FooterMenuLinkProps) {
    const { url, title, domain } = props;

    if (!url) {
        console.error('missing url for', props);
        return (
            <Link to="/" className={classes.link}>
                {title}
            </Link>
        );
    }

    // has domain
    if (url.includes(domain)) {
        return (
            <Link to={stripDomain(url)} className={classes.link}>
                {title}
            </Link>
        );
    }

    // is External
    if (url.includes('https://' || 'http://')) {
        return (
            <a href={url} className={classes.link} target="_blank" rel="noreferrer">
                {title}
            </a>
        );
    }

    // has no domain
    return (
        <Link to={url} className={classes.link}>
            {title}
        </Link>
    );
}
