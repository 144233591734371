require('intl');
require('intl/locale-data/jsonp/en.js');

import 'core-js/web/url-search-params';

/**
 * IE11 does not have window.location.origin...
 * https://tosbourn.com/a-fix-for-window-location-origin-in-internet-explorer/
 */
if (!window.location.origin) {
    window.location.origin =
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '');
}
