import React, { useContext } from 'react';

import { useDeps } from 'src/hooks/useDeps';

import classes from './AppShell.scss';
import { ConfigContext } from './ConfigProvider';

export function GlobalBanner() {
    const { cmsBlocks } = useContext(ConfigContext);
    const { pbExtractHtml } = useDeps();
    if (!cmsBlocks.globalBanner) return null;
    return (
        <div className={classes.banner} dangerouslySetInnerHTML={{ __html: pbExtractHtml(cmsBlocks.globalBanner) }} />
    );
}
