import React, { HTMLAttributes } from 'react';

import classes from './PageTitle.scss';

type Props = {
    title: string;
    variant?: 'normal' | 'with-subheading' | 'slim';
} & HTMLAttributes<any>;

export function PageTitle(props: Props) {
    const { title, ...rest } = props;
    return (
        <h1 className={classes.pageTitle} data-test-id="PageTitle" {...rest} data-variant={props.variant}>
            {title}
            {props.children}
        </h1>
    );
}

PageTitle.defaultProps = { variant: 'normal' };
