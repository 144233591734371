import { ArchiveCategoryDataRaw } from '../types';

export const normalizeArchiveData = (archive?: ArchiveCategoryDataRaw) => {
    const archiveData: any[] = [];

    archive?.edges?.forEach((data) => {
        const item = {
            categoryId: data?.node?.id ?? undefined,
            categoryTitle: data?.node?.name ?? undefined,
            categoryDescription: data?.node?.description ?? undefined,
        };

        if (item) archiveData.push(item);
    });

    archive?.nodes?.forEach((data) => {
        const nodeItem =
            {
                seo: {
                    breadcrumbs: data?.seo?.breadcrumbs ?? undefined,
                    schema: data?.seo?.schema ?? undefined,
                    title: data?.seo?.title ?? undefined,
                    metaDesc: data?.seo?.metaDesc ?? undefined,
                    metaKeywords: data?.seo?.metaKeywords ?? undefined,
                    focusKeywords: data?.seo?.focusKeywords ?? undefined,
                    opengraph: {
                        ogTitle: data?.seo?.opengraphTitle ?? undefined,
                        ogType: data?.seo?.opengraphType ?? undefined,
                        ogURL: data?.seo?.opengraphUrl ?? undefined,
                        ogDescription: data?.seo?.opengraphDescription ?? undefined,
                        ogSiteName: data?.seo?.opengraphSiteName ?? undefined,
                        ogArticlePublished: data?.seo?.opengraphPublishedTime ?? undefined,
                    },
                },
            } ?? undefined;

        if (nodeItem) archiveData.push(nodeItem);
    });

    return archiveData;
};

export default normalizeArchiveData;
