import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { skip } from 'rxjs/operators';

import { StoreState } from 'src/types/global-types';
import { AppMsg } from 'src/features/app/app.actions';
import { useSignedInObservable } from 'src/hooks/router-hooks';
import { useDeps } from 'src/hooks/useDeps';

import { ModalPortalType } from './ModalPortal.types';
import { ModalPortal } from './ModalPortal';

/**
 * `ModalPortal` connected to global store.
 */
export function ConnectedModalPortal() {
    const {
        paths: {
            customer: { account: accountDashboardPath },
        },
        replace,
    } = useDeps();
    const dispatch = useDispatch();

    /**
     * Observe the global signedIn variable
     */
    const signedInObservable = useSignedInObservable();

    // Get the current modal state from redux store
    const { isActive, type } = useSelector((state: StoreState) => state.app.modalState);

    /**
     * Close the active modal
     */
    const closeModal = useCallback(() => dispatch(AppMsg('App.Modal.Close')), [dispatch]);

    /**
     * Set the active modal type
     */
    const setModalType = useCallback(
        (type: ModalPortalType) => dispatch(AppMsg('App.Modal.SetType', type)),
        [dispatch],
    );

    /**
     * Redirect to the account dashboard
     */
    const goToAccountDashboard = useCallback(() => {
        dispatch(replace(accountDashboardPath));
    }, [dispatch, accountDashboardPath, replace]);

    /**
     * Action to be taken when the global signed in
     * variable has changed.
     */
    const signedInHasChanged = useCallback(
        (isSignedIn) => {
            if (isSignedIn) {
                if (type === ModalPortalType.Register) {
                    goToAccountDashboard();
                }

                closeModal();
            }
        },
        [closeModal, goToAccountDashboard, type],
    );

    // Subscribe to signedInObservable when a modal is active.
    // There is no point subscribing when a modal is not active.
    useEffect(() => {
        // Only subscibe to signedInObservable if modal is active
        // Note: `skip(1)` is used to ignore the initial value
        // returned after subscribing.
        const subscription = isActive ? signedInObservable.pipe(skip(1)).subscribe(signedInHasChanged) : null;

        // Unsubscribe from signedInObservable (if existing subscription
        // exists) when the component unmounts.
        return () => subscription?.unsubscribe();
    }, [closeModal, isActive, signedInHasChanged, signedInObservable]);

    return (
        <ModalPortal
            context={{
                closeModal,
                isActive,
                setModalType,
                type,
            }}
        />
    );
}
