import React, { ReactNode } from 'react';

import { useDeps } from 'src/hooks/useDeps';
import useNostoScript from 'src/components/Nosto/hooks/useNostoScript';

export type NostoContextType = {
    isLoaded: boolean;
};

export const defaultNostoContext = {
    isLoaded: false,
};

export const NostoContext = React.createContext<NostoContextType>(defaultNostoContext);

export type NostoProviderProps = {
    children: ReactNode;
};

export const NostoContextProvider = (props: NostoProviderProps) => {
    const { NOSTO_ID } = useDeps().env;
    const { isLoaded } = useNostoScript(NOSTO_ID);

    const api = {
        isLoaded,
    };

    return <NostoContext.Provider value={api}>{props.children}</NostoContext.Provider>;
};

export default NostoContextProvider;
