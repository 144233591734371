import { setContext } from 'apollo-link-context';
import { merge, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { STORAGE_KEY, UserActions } from '@wearejh/m2-pwa-user/lib';
import { ignoreElements, pluck, tap, withLatestFrom } from 'rxjs/operators';
import { Actions as RouterActions, LOCATION_CHANGE } from '@wearejh/m2-pwa-engine/lib/router/router.register';

import { nostoUpdateCart } from 'src/features/nostoTracking/epics/nostoUpdateCart';

import { externalLink } from './epics/externalLink.epic';
import { appReducer } from './app.reducer';
import { alertEpic } from './epics/alert.epic';

export function appRegister() {
    let token = null;
    const link = setContext((request, previousContext) => {
        const next = {
            ...previousContext.headers,
        };

        /**
         * This is to allow none-visible products in queries
         */
        switch (request.operationName) {
            case 'productVariant': {
                next['Allow-NonVisible'] = 'true';
                break;
            }
        }

        /**
         * This is to allow authenticated gql requests
         */
        if (token !== null) {
            next['Authorization'] = `Bearer ${token}`;
        }

        /**
         * This is what the setContext link expects
         */
        return {
            headers: next,
        };
    });
    return {
        epics: [
            alertEpic,
            externalLink,
            nostoUpdateCart,
            (actions: Observable<any>, state$: Observable<any>, deps) => {
                const initial = deps.storage.get(STORAGE_KEY);
                if (initial && initial.token) {
                    token = initial.token;
                }
                const token$ = state$.pipe(pluck('user', 'token'));
                const signIn$ = actions.pipe(
                    ofType<UserActions>('User.SignInSuccess', 'User.Persist'),
                    withLatestFrom(token$),
                    tap(([, _token]) => (token = _token)),
                );
                const signOut$ = actions.pipe(
                    ofType<UserActions>('User.SignOut', 'User.Reset'),
                    tap(() => (token = null)),
                );
                return merge(signIn$, signOut$).pipe(ignoreElements());
            },
            (actions$: Observable<any>) => {
                return actions$.pipe(ofType<RouterActions>(LOCATION_CHANGE), tap(), ignoreElements());
            },
        ],
        reducers: {
            app: appReducer,
        },
        name: 'app',
        links: [link],
    };
}
