export function stripDomain(url?: string | null): string {
    if (!url) return '';
    /**
     * if the URL begins '//' then it's an attempt
     * to bypass protocol problems, so just ensure we
     * strip it all the same
     */
    if (url[0] === '/' && url[1] === '/') return stripDomain('https:' + url);
    return (
        '/' +
        (url || '')
            .replace(/^https?:\/\//, '')
            .split('/')
            .slice(1)
            .join('/')
    );
}

export function stripDomainForMedia(url: string) {
    if (!url || typeof url !== 'string') {
        return '';
    }

    return url.replace(/(https?:\/\/|\/\/)(.*acme)(.+?)\/media/g, '/media');
}
